import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NotificationService } from '../../shared/notification.service';
import { BaseCrudService } from '../base-crud.service';
import { OnInit, Directive, ChangeDetectorRef } from '@angular/core';
import { CrudEntity, Filter, FilterOperator, FilterOptions, PagedServerResponse } from '@kdose/ng-crud';
import { DateTime } from 'luxon';

/**
 * Basis-Listenkomponente
 */
@Directive()
export class NextaBaseList<T extends CrudEntity> implements OnInit {

  entities: T[] = [];
  totalsize: number;
  pages: Array<{ page: number, active: boolean }>;
  loading = false;
  sort: { active: string, direction: 'asc' | 'desc' };
  filter: Filter[] = [];
  page = 1;
  size = 10;

  constructor(
    protected routeName: string,
    protected router: Router,
    protected crudService: BaseCrudService,
    protected notificationService: NotificationService,
    protected route: ActivatedRoute,
    protected listName: string,
    protected cdr: ChangeDetectorRef,
    protected persistStateInUrl = true
  ) {
  }

  public ngOnInit() {
    this.route.params.subscribe(params => this.handleParamsChange(params));
  }

  handlePageChange(page: { page: number, rows: number }): void {
    if (page.rows === this.size && page.page === this.page - 1) {
      return;
    }
    this.size = page.rows;
    localStorage.setItem('listSizes', this.size.toString(10))
    this.page = page.page + 1;
    this.loadEntities();
  }

  /**
   * Listenstatus aus URL entnehmen.
   */
  protected handleParamsChange(params): void {
    if (!this.persistStateInUrl) {
      return;
    }

    this.page = parseInt(params.page, 10) || 1;
    this.sort = params.sort
      ? (JSON.parse(decodeURIComponent(params.sort)) || null)
      : null;
    this.size = parseInt(params.size, 10) || 10;
    this.filter = params.filter
      ? (JSON.parse(decodeURIComponent(params.filter)) || [])
      : [];

    this.loadEntities(true);
  }

  /**
   * Listenstatus zum Persistieren in URL vorbereiten.
   * Nur die Objekte persistieren, die geändert wurden.
   * @return {Object} Objekt für Router.navigate
   */
  protected encodeStateForUrl(): Object {
    const stateObj = {};
    if (this.page > 1) {
      stateObj['page'] = this.page;
    }

    if (this.sort && 'active' in this.sort) {
      stateObj['sort'] = encodeURIComponent(JSON.stringify(this.sort));
    }

    if (this.size > 10) {
      stateObj['size'] = this.size;
    }

    if (this.filter.length > 0) {
      stateObj['filter'] = encodeURIComponent(JSON.stringify(this.filter));
    }

    return stateObj;
  }

  /**
   * Laden der Entitäten mittels des CrudService.
   */
  protected loadEntities(skipUrlPersisting = false): void {
    if (!(this.page >= 1)) {
      this.page = 1;
    }

    // Listenstatus in URL persistieren
    if (this.persistStateInUrl && !skipUrlPersisting) {
      const encodedState = this.encodeStateForUrl();
      this.router.navigate(['/' + this.routeName, encodedState]);
    }

    this.loading = true;

    this.doRequest({
      page: this.page - 1,
      size: this.size,
      sort: this.sort ? encodeURIComponent(JSON.stringify(this.sort)) : undefined,
      filter: this.filter
    }).subscribe((res) => {
      this.entities = res.content;
      this.totalsize = res.totalElements;
      this.loading = false;

      if (this.entities === undefined) {
        this.entities = [];
      }
      this.cdr.markForCheck();
    });
  }

  protected doRequest(options?: FilterOptions): Observable<PagedServerResponse<T>> {
    return this.crudService.getAll(options);
  }

  /**
   * Filter hinzufügen
   * @param {string}     property             Zu filterndes Feld
   * @param {string}     value                Wert
   * @param {string}     operator             <,>,=
   * @param {boolean}    overwrite            Überschreiben des Filters (default: false)
   * @param {boolean}    reloadAutomatically  Aufrufen von loadEntities() (default: true)
   */
  addFilter(
    property: string,
    value: string | Date | DateTime,
    operator: FilterOperator,
    overwrite = false,
    reloadAutomatically = true
  ): void {
    let add = true;

    if (value instanceof Date) {
      value = DateTime.fromJSDate(value);
    }
    if (value instanceof DateTime) {
      value = value.toUTC().toISO();
    }

    if (overwrite) {
      this.filter.forEach(filter => {
        if (filter.property === property) {
          filter.value = value as string;
          filter.operator = operator;
          add = false;
        }
      });
    }

    if (add) {
      this.filter.push({ property, value, operator });
    }

    if (reloadAutomatically) {
      this.page = 1;
      this.loadEntities();
    }
  }

  /**
   * Filter entfernen.
   * @param {string}     property             Zu entfernendes Feld
   * @param {boolean}    reloadAutomatically  Aufrufen von loadEntities() (default: true)
   */
  removeFilter(property: string, reloadAutomatically = true): void {
    this.filter = this.filter
      .filter((value: {}) => (<any>value).property !== property);

    if (reloadAutomatically) {
      this.page = 1;
      this.loadEntities();
    }
  }

  /**
   * Suche starten
   * @param {any} evt  KeyboardEvent des Eingabefeldes
   * @param {string}        name Zu durchsuchendes Feld
   */
  search(evt, name: string, operator: FilterOperator = '='): void {
    // Entertaste führt aus
    if (evt.keyCode !== 13) {
      return;
    }

    this.addFilter(name, evt.target.value, operator, true);
  }

  /**
   * Sortierung für ein Feld setzen bzw. togglen zwischen
   * 'asc' und 'desc';
   * @param  {string} active Zu sortierendes Feld
   */
  toggleSort(active: string): void {
    // Sortierrichtung bestimmen
    const direction = (
      this.sort &&
      this.sort.active === active &&
      this.sort.direction === 'asc'
    ) ? 'desc' : 'asc';

    this.sort = {
      active,
      direction
    };

    // Entitäten neu laden
    this.loadEntities();
  }

  /**
   * Gibt die Sortierrichtung für das aktuelle Feld zurück.
   * @param  {string} active Zu prüfendes Feld
   * @return {string}       Sortierrichtung: asc, desc, none
   */
  getSortingDir(active: string): string {
    if (!this.sort || this.sort.active !== active) {
      return 'none';
    }

    return this.sort.direction;
  }
  /**
   * Click-Event-Handler zum Löschen einer Entität T.
   * @param {[type]} event  Click-Event
   * @param {T}      entity Zu löschende Entität
   */
  onDelete(event, entity: T): void {
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }

    this.notificationService.confirm('Soll der Eintrag gelöscht werden?', (result) => {
      if (!result) {
        return;
      }

      this.loading = true;
      this.crudService.delete(entity).subscribe((response) => {
        this.loading = false;

        // Erfolgreicher Status = 204 (No Content)
        if (response.status === 204) {
          this.notificationService.notify('Der Eintrag wurde erfolgreich gelöscht.');
          this.loadEntities();
          return;
        }

        this.notificationService.notify(
          'Der Eintrag konnte nicht gelöscht werden.',
          'error'
        );
      });
    });
  }
}
