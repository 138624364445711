import { NgModule } from '@angular/core';
import { TabViewModule  } from 'primeng/tabview';
import { SharedModule } from '../shared';

import { PipesModule } from '../pipes/pipes.module';
import { KeyFormComponent } from './key-form.component';
import { KeyRoutingModule } from './key-routing.module';
import { CertificateCrudModule } from './certificate/certificate.module';
import { PrivateKeyCrudModule } from './private-key/private-key.module';

const DIRECTIVES = [
  KeyFormComponent
];

@NgModule({
  imports: [
    SharedModule,
    TabViewModule,
    CertificateCrudModule,
    PrivateKeyCrudModule,
    PipesModule,
    KeyRoutingModule
  ],
  declarations: [
    ...DIRECTIVES
  ],
  exports: [
    ...DIRECTIVES
  ]
})
export class KeyCrudModule {
}
