import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-base-list-info',
  styles: [`
    :host ::ng-deep p-dialog {
      text-align: initial;
    }
  `],
  template: `
    <button class="btn btn-default btn-sm" (click)="showInfoText = true" type="button" title="Info"><i class="fa fa-info-circle"></i></button>
    <p-dialog
      [dismissableMask]="true"
      [draggable]="false"
      [modal]="true"
      [resizable]="false"
      [(visible)]="showInfoText">
      <ng-template pTemplate="header">
        Es bestehen folgende Filtermöglichkeiten:
      </ng-template>
      <ul>
        <li><b>=</b> Das Ergebnis muss exakt dem eingegebenen Wert entsprechen.</li>
        <li><b>:</b> Das Ergebnis muss den eingegebenen Wert enthalten. Gilt nur für Texte und URIs.</li>
        <li><b>&lt;</b> Das Ergebnis ist kleiner als der eingegebene Wert. Gilt nur für Zahlen und Datumsangaben.</li>
        <li><b>&gt;</b> Das Ergebnis ist größer als der eingegebene Wert. Gilt nur für Zahlen und Datumsangaben.</li>
      </ul>
      <ng-template pTemplate="footer">
        <button class="btn btn-primary" autofocus (click)="showInfoText = false" type="button">OK</button>
      </ng-template>
    </p-dialog>
  `
})
export class BaseListInfoComponent {
  get showInfoText() {
    return this._showInfoText;
  }

  set showInfoText(value: boolean) {
    this._showInfoText = value;
    this.cdr.markForCheck();
  }

  private _showInfoText = false;

  constructor(private cdr: ChangeDetectorRef) { }


}
