import { from as observableFrom, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { NotificationService } from './../shared/notification.service';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CrudEntity, CrudHelperService, FilterOptions, PagedServerResponse } from '@kdose/ng-crud';
import { baseCrudUrlGetter } from '../config-getter';

/**
 * Für ältere Anwendung steht dieser Service bereit. Dieser kann in die eigene Anwendung kopiert
 * werden und ersetzt den bisherigen BaseCrudService aus import { BaseCrudEntity } from '../../shared/crud/crud.entity';
 , damit nicht jeder Service auf den
 * CrudHelperService umgestellt werden muss. Dieser Service verwendet den CrudHelperService.
 *
 * Folgende Umstellung sind dennoch an den Services notwendig:
 * - CrudHelperService muss als zweiter Parameter übergeben werden
 * - Http muss durch HttpClient ersetzt werden.
 * - Die Parameter oAuthService und config entfallen.
 * - `.map(res => res)` muss entfernt werden, da standardmäßig JSON geliefert wird.
 * - Der verwendete NotificationService muss `LegacyNotificationService`  implementieren.
 */
export abstract class BaseCrudService {

  sort: Object;
  filter: Array<any>;
  size = 10;
  page = 1;

  constructor(
    protected endpoint: string,
    protected crudHelperService: CrudHelperService,
    protected notifyService: NotificationService,
    protected router: Router,
    protected http: HttpClient
  ) {

  }

  /**
   * Standard-GET-Request an url mit anschließender Fehlerbehandlung.
   * @param url GET-URL
   * @param appendToUrl
   */
  protected defaultShareableGET(url: string, appendToUrl = '', options?): Observable<any> {
    if (appendToUrl) {
      url += '/' + appendToUrl;
    }

    return observableFrom(baseCrudUrlGetter()).pipe(
      mergeMap(baseUrlAsync => this.http.get(baseUrlAsync + url), options));
  }

  /**
   * Laden eines einzelnen Objekts.
   * @param  {number} id ID
   * @return {Rx.Observable} hot Observable
   */
  get<T extends CrudEntity = any>(id: number, appendToUrl = ''): Observable<T> {
    return this.defaultShareableGET(this.endpoint + '/' + id, appendToUrl);
  }

  /**
   * Laden aller Objekte.
   * @param options {
     page: number,
     size: number,
     filter: Array<any>,
     sort: Object
   }
   * @return hot Observable
   */
  getAll<T extends CrudEntity = any>(options: FilterOptions = {}, appendToUrl = '', suffixToUrl = ''): Observable<PagedServerResponse<T>> {
    if (suffixToUrl) {
      suffixToUrl = suffixToUrl + '/';
    }

    if (appendToUrl) {
      appendToUrl = '/' + appendToUrl;
    }

    if (options?.sort && (options.sort as any).active && (options.sort as any).direction) {
      options.sort = encodeURIComponent(JSON.stringify(options.sort));
    }

    return this.crudHelperService.getAll<T>(suffixToUrl + this.endpoint + appendToUrl, options);
  }

  /**
   * Löschen eines Objekts. Hier wird der Custom-Content-Type verwendet.
   * @param  entity Zu löschende Entität.
   * @return {Rx.Observable} hot Observable
   */
  delete<T extends CrudEntity>(entity: T): Observable<HttpResponse<any>> {
    return this.crudHelperService.delete(this.endpoint + '/' + entity.id);
  }

  /**
   * Speichern (create und update) eine Entität. Hier wird der Custom-Content-Type verwendet.
   * @param  entity Zu speichernde Entität.
   * @return {Rx.Observable} hot Observable
   */
  save<T extends CrudEntity = any>(entity: T, clean = true): Observable<T> {
    return this.crudHelperService.save(this.endpoint, entity, undefined, clean);
  }

  onTokenExpired(): void {
    this.notifyService.notify(
      'Ihre Sitzung ist abgelaufen.',
      'error'
    );
    localStorage.removeItem('access_token');
    this.router.navigate(['/login']);
  }
}
