<div class="form-horizontal key-form">
  <p-tabView>
    <p-tabPanel header="Zertifikate">
      <app-certificate-list></app-certificate-list>
    </p-tabPanel>
    <p-tabPanel header="Private Schlüssel">
      <app-private-key-list></app-private-key-list>
    </p-tabPanel>
  </p-tabView>
</div>
