import { Routes } from '@angular/router';
import { LoginComponent } from '@kdose/ng-auth-primeng';

export const ROUTES: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/behoerden'
  },
];
