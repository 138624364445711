import {
  UntypedFormGroup
} from '@angular/forms';

export class NextaFileReader extends FileReader {
  form: UntypedFormGroup;
  name: string;

  constructor(form: UntypedFormGroup, name: string) {
    super();
    this.form = form;
    this.name = name;
  }

  onloadend = () => {
    this.form.controls[this.name].setValue(this.result);
  }
}
