<div class="row row-centered">
  <div class="col-xs-12 col-sm-12 col-md-6 col-centered">
    <div class="panel panel-default">
      <div class="panel-heading">
        Privaten Schlüssel erstellen
      </div>

      <div class="panel-body">
        <form (submit)="onSubmit()" [formGroup]="form" class="form-horizontal">
          <app-form-group [group]="form" [biggerLabel]="true" label="Zertifikatsdatei (PKCS12)" control="file">
            <div class="input-wrapper">
                Datei auswählen
                <input class="file-input file-input-hidden" type="file" name="file" (change)="onFileSelected($event)"/>
            </div>
            <div class="file-input-selection" *ngIf="file"> {{ file.name }}</div>
          </app-form-group>

          <app-form-group [biggerLabel]="true" control="pinP12" label="Pin Keystore">
            <input
              id="pinP12"
              type="password"
              placeholder="Pin Keystore"
              formControlName="pinP12"
              class="form-control">
          </app-form-group>

          <app-form-group [biggerLabel]="true" control="pinKey" label="Pin privater Schlüssel">
            <input
              id="pinKey"
              type="password"
              placeholder="Pin privater Schlüssel"
              formControlName="pinKey"
              class="form-control">
          </app-form-group>

          <div class="form-group">
            <div class="col-sm-offset-3 col-sm-9">
              <button class="btn btn-primary" type="submit">Speichern</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
