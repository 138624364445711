import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Input } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective, AbstractControl } from '@angular/forms';

/**
 * Komponente zum Anzeigen von Formularfehlern als Badge.
 * Die zu prüfende group kann eine einzelne sein - diese wird dann als
 * String übergeben. Es können aber auch mehrere Gruppen geprüft werden -
 * diese sind dann als Array zu übergeben:
 *
 * String:
 * <app-error-badge group="processors"></app-error-badge>
 *
 * Array
 * <app-error-badge [group]="['processors', 'targets']"></app-error-badge>
 *
 * TODO: Unittests und Auslagern in Bundle
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-error-badge',
  template: `
  <span *ngIf="errorCnt > 0" class="badge">
    {{ errorCnt }}
  </span>
  `
})
export class ErrorBadgeComponent {
  @Input() group: string | Array<string>;
  errorCnt = 0;
  changeSubscription;

  constructor(public form: FormGroupDirective, private cdr: ChangeDetectorRef) {
    form.ngSubmit.subscribe(() => {
      // Subscription speichern, um diese nicht bei jedem Submit
      // zu registrieren.
      if (!this.changeSubscription) {
        this.update();
        this.changeSubscription = form.control.valueChanges.subscribe(() => this.update());
      }
    });
  }

  /**
   * Aktualisieren der Fehleranzahl.
   */
  update(): void {
    this.errorCnt = 0;

    // prüfen, ob String oder Array
    if (typeof this.group === 'string') {
      this.errorCnt = this.countErrors(
        <UntypedFormGroup>this.form.control.controls[this.group]
      );
    } else {
      for (const group of <string[]>this.group) {
        this.errorCnt += this.countErrors(
          <UntypedFormGroup>this.form.control.controls[group]
        );
      }
    }
    this.cdr.markForCheck();
  }

  /**
   * Zählt die Fehler in der FormGroup und gibt diese zurück.
   *
   * @param  {FormGroup} group Aktuell zu prüfende Gruppe
   * @return {number}          Fehleranzahl in group
   */
  private countErrors(group: UntypedFormGroup): number {
    let cnt = 0;

    if (!group) {
      return cnt;
    }

    for (const controlName of Object.keys(group.controls)) {
      const control: AbstractControl = group.get(controlName);

      if (control instanceof UntypedFormGroup) {
        cnt += this.countErrors(control);
      } else if (!control.disabled && !control.valid) {
        cnt++;
      }
    }

    return cnt;
  }
}
