import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CertificateDetails } from '.';
import { NotificationService } from '../../shared/notification.service';
import { CertificateService } from './crud.service';
import { DownloadHelperService } from '@kdose/ng-crud';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./certificate-show.component.scss'],
  templateUrl: './certificate-show.component.html'
})
export class CertificateShowComponent implements OnInit {

  public issuerCertificateID: string;
  public certificateDetails: CertificateDetails;

  constructor(private crudService: CertificateService,
    private notify: NotificationService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef) {
  }

  /**
   * Details-Methode muss benutzt werden
   */
  ngOnInit() {
    if (typeof this.route.snapshot.params['id'] !== 'undefined') {
      this.crudService.getDetails(+this.route.snapshot.params['id'])
        .subscribe((res) => {
          this.certificateDetails = res;
          this.onDataLoaded();
          this.cdr.markForCheck();
        }, (error: HttpErrorResponse) => {
          if (error.status === 404) {
            window.history.back();
            this.notify.notify('Element wurde nicht gefunden.', 'warning');
            return;
          }
        });
    }
  }

  onDataLoaded() {
    if (this.certificateDetails.certificate.issuerX500Principal !== this.certificateDetails.certificate.subjectX500Principal) {
      this.crudService.get(this.certificateDetails.certificate.id, 'issuer').subscribe(res => {
        if (res) {
          this.issuerCertificateID = res.id
        }
        this.cdr.markForCheck();
      });
    }
  }

  downloadFile(evt: UIEvent): void {
    evt.preventDefault();
    this.crudService
      .getX509(this.certificateDetails.certificate.id)
      .subscribe(data => {
          const ds = new DownloadHelperService();
          const blob = new Blob([data]);
          ds.downloadFile(blob, 'zertifikat' + this.certificateDetails.certificate.id + '.der');
      });
  }
}
