import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';

import { PipesModule } from '../../pipes/pipes.module';
import { PrivateKeyService } from './crud.service';
import { PrivateKeyFormComponent } from './private-key-form.component';
import { PrivateKeyShowComponent } from './private-key-show.component';
import { PrivateKeyListComponent } from './private-key-list.component';
import { PrivateKeyViewComponent } from './private-key-view.component';
import { PrivateKeyRoutingModule } from './private-key-routing.module';

const DIRECTIVES = [
  PrivateKeyFormComponent,
  PrivateKeyShowComponent,
  PrivateKeyListComponent,
  PrivateKeyViewComponent
];

@NgModule({
  imports: [
    SharedModule,
    PipesModule,
    PrivateKeyRoutingModule
  ],
  providers: [
    PrivateKeyService
  ],
  declarations: [
    ...DIRECTIVES
  ],
  exports: [
    ...DIRECTIVES
  ]
})
export class PrivateKeyCrudModule {
}
