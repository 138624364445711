<div class="wrapper" id="wrapper">

  <app-sidenav #sidenav></app-sidenav>

  <div class="page-content-wrapper">
    <div class="container-fluid">
      <div class="row">
        <app-navbar (toggleSidebar)="sidenav.toggle()"></app-navbar>

        <div class="col-lg-12 content">
          <router-outlet></router-outlet>
          <p-confirmDialog defaultFocus="reject" acceptButtonStyleClass="p-button-danger"
            rejectButtonStyleClass="p-button-secondary p-button-outlined" key="main-danger"></p-confirmDialog>

          <p-confirmDialog defaultFocus="reject" acceptButtonStyleClass="p-button-primary"
            rejectButtonStyleClass="p-button-secondary p-button-outlined" key="main"></p-confirmDialog>

          <p-toast position="top-center" key="main" [preventOpenDuplicates]="true"></p-toast>

          <p-toast position="center" key="main-link">
            <ng-template let-message pTemplate="message">
              <div class="p-flex p-flex-column flex-grow">
                <div class="p-text-center">
                  <i class="pi pi-exclamation-triangle size-triple"></i>
                  <h4>{{message.summary}}</h4>
                  <a [href]="message.targetUrl" target="_self">{{message.detail}}</a>
                </div>
              </div>
            </ng-template>
          </p-toast>

          <p-toast position="center" key="main-sticky">
            <ng-template let-message pTemplate="message">
              <div class="p-flex p-flex-column flex-grow">
                <div class="p-text-center">
                  <i class="pi pi-exclamation-triangle size-triple"></i>
                  <h4>{{message.summary}}</h4>
                  <p>{{message.detail}}</p>
                </div>
                <div class="p-grid p-fluid">
                  <div class="p-col-6"></div>
                  <div class="p-col-6">
                    <button type="button" pButton (click)="dismissMessage('main-sticky')" label="OK"
                      class="p-button-primary"></button>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-toast>

        </div>
      </div>
    </div>
  </div>
</div>
