import { HTTP_OPTIONS, HttpOptions } from './http-options.token';
import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpHandler } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpSentEvent } from '@angular/common/http';
import { HttpHeaderResponse } from '@angular/common/http';
import { HttpProgressEvent } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { HttpUserEvent } from '@angular/common/http';

/**
 * `HttpInterceptor` für das Anhängen des eigenen ContentTypes an einen HTTP-Request
 * mit der Klasse `HttpClient`.
 */
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  contentType: string;

  constructor(@Inject(HTTP_OPTIONS) config: HttpOptions) {
    this.contentType = config.contentType;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent
    | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    // entferne Custom-Header, damit dieser keine CORS-Fehler verursacht
    if (request.headers.has('X-KDO-NO-AUTH')) {
      request = request.clone({
        headers: request.headers.delete('X-KDO-NO-AUTH')
      });
    }

    if (request.headers.has('X-KDO-NO-CONTENT-TYPE')) {
      request = request.clone({
        headers: request.headers.delete('X-KDO-NO-CONTENT-TYPE')
      });
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        'Content-Type': this.contentType,
        Accept: '*/*'
      }
    });

    return next.handle(request);
  }
}
