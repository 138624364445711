import { CertificateCrudModule } from './../key/certificate/certificate.module';
import { PrivateKeyCrudModule } from './../key/private-key/private-key.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { EndPointRoutingModule } from './end-point-routing.module';
import { EndPointListComponent } from './end-point-list.component';

const DIRECTIVES = [
  EndPointListComponent
];

@NgModule({
  imports: [
    SharedModule,
    EndPointRoutingModule,
    PrivateKeyCrudModule,
    CertificateCrudModule
  ],
  declarations: [
    ...DIRECTIVES
  ],
  exports: [
    ...DIRECTIVES
  ]
})
export class EndPointModule {
}
