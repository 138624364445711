<div class="panel panel-default">
  <div class="panel-heading">
    <h3 class="panel-title">
      Behörden - Liste <small *ngIf="loading">Lädt...</small>
      <span class="pull-right">
        <button (click)="setFilter($event)" class="btn btn-default"
          [title]="'Filter' + (showFilter ? ' ausblenden': ' einblenden')">
          <i class="fa fa-filter"></i> Filter {{ showFilter ? 'ausblenden' : 'einblenden' }}
        </button>
        <span>
          <a [routerLink]="['/behoerden/neu']" class="btn btn-success">
            <i class="fa fa-plus"></i> Neue Behörde anlegen
          </a>
        </span>
      </span>
    </h3>
  </div>

  <form (submit)="onFilter(false)" [formGroup]="form">
    <table class="table table-striped" *ngIf="entities.length > 0 || showFilter">
      <thead>
        <tr>
          <th>
            ID
          </th>
          <th>
            <a (click)="toggleSort('prefix')" [class]="'sort-' + getSortingDir('prefix')">Präfix</a>
          </th>
          <th>
            <a (click)="toggleSort('identifier')" [class]="'sort-' + getSortingDir('identifier')">Behördenkennung</a>
          </th>
          <th>
            <a (click)="toggleSort('active')" [class]="'sort-' + getSortingDir('active')">Aktiv</a>
          </th>
          <th>
            <a (click)="toggleSort('target')" [class]="'sort-' + getSortingDir('target')">Endpunkt</a>
          </th>
          <th class="right-align">Aktionen</th>
        </tr>
        <tr *ngIf="showFilter">
          <th class="search-column">
            <app-search-column elementType="number" placeholderValue="ID" formGroupNameValue="id" [data]="filter">
            </app-search-column>
          </th>
          <th class="search-column">
            <app-search-column placeholderValue="Behördenpräfix" formGroupNameValue="prefix" [data]="filter">
            </app-search-column>
          </th>
          <th class="search-column">
            <app-search-column placeholderValue="Behördenkennung" formGroupNameValue="identifier" [data]="filter">
            </app-search-column>
          </th>
          <th class="search-column">
            <app-search-column elementType="boolean" placeholderValue="Aktiv" formGroupNameValue="active"
              [data]="filter"></app-search-column>
          </th>
          <th class="search-column">
            <app-search-column placeholderValue="Endpunkt" formGroupNameValue="target.to" [data]="filter"></app-search-column>
          </th>
          <th class="search-column filter-buttons right-align">
            <button class="btn btn-default btn-sm" type="submit" title="Filtern"><i class="fa fa-filter"></i></button>
            <button class="btn btn-default btn-sm" (click)="onFilter(true)" type="button" title="Filter aufheben"><i
                class="fa fa-ban"></i></button>
            <app-base-list-info></app-base-list-info>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let authority of entities">
          <ng-container *ngIf="authority.defaultRoute">
            <td>
              {{ authority.id }}
            </td>
            <td>
              <a [routerLink]="['/behoerden', authority.id]">
                {{ authority.prefix }}
              </a>
            </td>
            <td>
              <a [routerLink]="['/behoerden', authority.id]">
                {{ authority.identifier }}
              </a>
            </td>
            <td>
              {{ authority.active ? 'Ja' : 'Nein'}}
            </td>
            <td>
              {{ authority.target.to }}
            </td>
            <td class="right-align">
              <a (click)="onDelete($event, authority)" href="#" class="btn btn-danger btn-sm">
                <i class="fa fa-trash-o"></i>
              </a>
            </td>
          </ng-container>
        </tr>
        <tr *ngFor="let authority of entities">
          <ng-container *ngIf="!authority.defaultRoute">
            <td>
              {{ authority.id }}
            </td>
            <td>
              <a [routerLink]="['/behoerden', authority.id]">
                {{ authority.prefix }}
              </a>
            </td>
            <td>
              <a [routerLink]="['/behoerden', authority.id]">
                {{ authority.identifier }}
              </a>
            </td>
            <td>
              {{ authority.active ? 'Ja' : 'Nein'}}
            </td>
            <td>
              {{ authority.to }}
            </td>
            <td class="right-align">
              <a (click)="onDelete($event, authority)" href="#" class="btn btn-danger btn-sm">
                <i class="fa fa-trash-o"></i>
              </a>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </form>

  <div class="panel-body" *ngIf="!loading && entities.length === 0">
    <div class="alert alert-info">
      Es wurden keine Einträge gefunden.
    </div>
  </div>

  <div class="panel-footer" *ngIf="entities.length > 0">
    <div class="row">
      <div class="col-sm-offset-6 col-sm-6">
        <p-paginator currentPageReportTemplate="Zeige Einträge {first} bis {last} von {totalRecords}."
          [showCurrentPageReport]="true" styleClass="pull-right" [rows]="size"
          [rowsPerPageOptions]="[10,20,50,100,200,{showAll:'Alle'}]" [totalRecords]="totalsize"
          (onPageChange)="handlePageChange($event)"></p-paginator>
      </div>
    </div>
  </div>
</div>
