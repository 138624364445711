import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'principalName'
})
export class PrincipalNamePipe implements PipeTransform {
  transform(value: string, onlyCn: boolean) {
    if (onlyCn) {
      const newValue = value.split(',');
      return newValue.filter(v => v.startsWith('CN'));
    } else {
      const regex = /,/gi;
      const newValue = value.replace(regex, ', ');
      return [newValue];
    }
  }
}
