import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthenticated } from '@kdose/ng-auth';
import { KeyFormComponent } from './key-form.component';

const keyRoutes: Routes = [{
  path: 'schluessel',
  component: KeyFormComponent,
  canActivate: [isAuthenticated],
}];
@NgModule({
  imports: [
    RouterModule.forChild(keyRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class KeyRoutingModule { }
