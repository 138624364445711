import { OnInit, Output, Directive, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Data } from './../data';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { Input, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Directive()
export abstract class NextaView implements OnInit {
  @Input() public title: string;
  /**
   * Name der FormGroup, zu der die View hinzugefügt werden soll.
   */
  @Input() public groupName: string;
  /**
   * Name der FormGroup, welche die View repräsentiert.
   */
  @Input() public groupNameNew: string;
  @Input() public readOnly: boolean;
  @Input() public data: any;
  @Input() public id: string;
  @Input() storageData: any;
  @Output() readonly clickOnNew = new EventEmitter<void>();

  group: UntypedFormGroup;
  groupNamePath: string[];

  constructor(
    protected parent: ControlContainer,
    protected form: FormGroupDirective,
    protected router: Router,
    protected acrossPagesData: Data,
    protected navigationPointForNewElement: string,
    protected cdr: ChangeDetectorRef) {
    this.initFormGroup();
  }

  abstract initFormGroup();

  ngOnInit() {
    if (this.groupName) {
      (this.form.form.get(this.groupName) as UntypedFormGroup).addControl(this.groupNameNew,
        this.group);
      this.groupNamePath = [this.groupName, this.groupNameNew];
    } else {
      this.form.form.addControl(this.groupNameNew, this.group);
      this.groupNamePath = [this.groupNameNew];
    }

    this.patchValue();
    this.cdr.markForCheck();
  }

  patchValue() {
    if (this.data) {
      this.group.patchValue(this.data);
    }
  }

  createNewElement() {
    this.acrossPagesData.storage.push(this.storageData);
    this.clickOnNew.emit();
    this.router.navigate([this.navigationPointForNewElement]);
  }
}
