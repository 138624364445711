import { NextaBaseList } from './../shared/nexta-crud/nexta-base-list';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../shared/notification.service';
import { AuthorityService } from '../authority/crud.service';
import { Authority } from '../authority/authority';
import { EndPoint as Endpoint } from './end-point';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    '.success_default_route {background-color: #b7e0c7;border-color: #b7e0c7;}'
  ],
  templateUrl: './end-point-list.component.html'
})
export class EndPointListComponent extends NextaBaseList<Authority> {
  endPoints: Endpoint[] = [];
  constructor(
    router: Router,
    authorityService: AuthorityService,
    notificationService: NotificationService,
    route: ActivatedRoute,
    cdr: ChangeDetectorRef
  ) {
    super('/routen', router, authorityService, notificationService, route, 'endPoints', cdr);
  }

  /**
   * Laden der Entitäten mittels des CrudService.
   */
  protected loadEntities(skipUrlPeristing = false): void {
    if (!(this.page >= 1)) {
      this.page = 1;
    }

    if (this.persistStateInUrl && !skipUrlPeristing) {
      this.router.navigate(['/' + this.routeName, this.encodeStateForUrl()]);
    }

    this.loading = true;

    this.doRequest({
      page: null,
      size: null,
      sort: JSON.stringify({ active: 'prefix', direction: 'desc' }),
      filter: null
    }).subscribe(res => {
      this.entities = res.content || [];
      this.entities.forEach(auth => {
        const endpoint: Endpoint = { url: auth.to, defaultRoute: auth.defaultRoute };
        const compareResult = this.endPoints.findIndex(item => item.url === endpoint.url);
        if (compareResult === -1) {
          this.endPoints.push(endpoint);
        } else {
          if (endpoint.defaultRoute === true) {
            this.endPoints[compareResult].defaultRoute = true;
          }
        }
      });

      this.loading = false;
      this.cdr.markForCheck();
    });
  }
}
