import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';

import { PipesModule } from '../../pipes/pipes.module';
import { CertificateService } from './crud.service';
import { CertificateFormComponent } from './certificate-form.component';
import { CertificateShowComponent } from './certificate-show.component';
import { CertificateSelectionListComponent } from './certificate-selection-list.component';
import { CertificateListComponent } from './certificate-list.component';
import { CertificateRoutingModule } from './certificate-routing.module';

const DIRECTIVES = [
  CertificateFormComponent,
  CertificateShowComponent,
  CertificateSelectionListComponent,
  CertificateListComponent
];

@NgModule({
  imports: [
    SharedModule,
    PipesModule,
    CertificateRoutingModule
  ],
  providers: [
    CertificateService
  ],
  declarations: [
    ...DIRECTIVES
  ],
  exports: [
    ...DIRECTIVES
  ]
})
export class CertificateCrudModule {
}
