import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@kdose/ng-auth';
import { MenuItem } from 'primeng/api';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sidenav',
  styleUrls: ['./sidenav.component.scss'],
  templateUrl: './sidenav.component.html'
})
export class SidenavComponent implements OnInit {
  public isLoggedIn = false;
  public menuItems: MenuItem[] = [];
  public showSidenav = true;

  constructor(
    private authService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    ) {
    this.authService.userData.subscribe((userData) => {
      this.isLoggedIn = !!userData;
      this.updateMenuItems();
    });
  }

  ngOnInit() {
    this.updateMenuItems();
  }

  public toggle() {
    this.showSidenav = !this.showSidenav;
    this.cdr.markForCheck();
  }

  private updateMenuItems() {
    if (!this.isLoggedIn) {
      this.menuItems = [{
        label: 'Login',
        items: [
          { label: 'Login', icon: 'fa fa-fw fa-lock', routerLink: '/login', routerLinkActiveOptions: 'active' }
        ]
      }];
      this.cdr.markForCheck();
      return;
    }

    this.menuItems = [{
      label: 'Monitoring',
      icon: 'fa fa-fw fa-line-chart',
      routerLink: '/monitoring',
      routerLinkActiveOptions: 'active'
    }, {
      label: 'Benutzer',
      icon: 'fa fa-fw fa-user',
      routerLink: '/benutzer',
      routerLinkActiveOptions: 'active'
    }, {
      label: 'Schlüssel',
      icon: 'fa fa-fw fa-key',
      routerLink: '/schluessel',
      routerLinkActiveOptions: 'active'
    }, {
      label: 'Behörden',
      icon: 'fa fa-fw fa-institution',
      routerLink: '/behoerden',
      routerLinkActiveOptions: 'active'
    }, {
      label: 'Clients',
      icon: 'fa fa-fw fa-id-card',
      routerLink: '/clients',
      routerLinkActiveOptions: 'active'
    }, {
      label: 'Routen',
      icon: 'fa fa-fw fa-link',
      routerLink: '/routen',
      routerLinkActiveOptions: 'active'
    }];

    this.cdr.markForCheck();
  }


}
