<div class="panel panel-default">
  <div class="panel-heading">
    <h3 class="panel-title">Benutzer - Liste <small *ngIf="loading">Lädt...</small>
      <span class="pull-right">
        <span>
          <a [routerLink]="['/benutzer/neu']" class="btn btn-success">
            <i class="fa fa-plus"></i> Neuen Benutzer anlegen
          </a>
        </span>
      </span>
    </h3>
  </div>

  <table class="table table-striped" *ngIf="entities.length > 0">
    <thead>
      <tr>
        <th>
          ID
        </th>
        <th>
          Benutzername
        </th>
        <th>
          Aktiv
        </th>
        <th class="right-align">Aktionen</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let user of entities">
        <td>
          {{ user.id }}
        </td>
        <td>
          <a [routerLink]="['/benutzer', user.id]">
            {{ user.username }}
          </a>
        </td>
        <td>
          <span *ngIf="user.enabled">Ja</span>
          <span *ngIf="!user.enabled">Nein</span>
        </td>
        <td class="right-align">
          <a (click)="onDelete($event, user)" href="#" class="btn btn-danger btn-sm">
            <i class="fa fa-trash-o"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="panel-body" *ngIf="!loading && entities.length === 0">
    <div class="alert alert-info">
      Es wurden keine Einträge gefunden.
    </div>
  </div>

  <div class="panel-footer" *ngIf="entities.length">
    <div class="row">
      <div class="col-sm-offset-6 col-sm-6">
        <p-paginator
          currentPageReportTemplate="Zeige Einträge {first} bis {last} von {totalRecords}."
          [showCurrentPageReport]="true"
          styleClass="pull-right"
          [rows]="size"
          [rowsPerPageOptions]="[10,20,50,100,200,{showAll:'Alle'}]"
          [totalRecords]="totalsize"
          (onPageChange)="handlePageChange($event)"></p-paginator>
      </div>
    </div>
  </div>
</div>
