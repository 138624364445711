import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivateKeyFormComponent } from './private-key-form.component';
import { PrivateKeyListComponent } from './private-key-list.component';
import { PrivateKeyShowComponent } from './private-key-show.component';
import { isAuthenticated } from '@kdose/ng-auth';

const privateKeyRoutes: Routes = [
  {
    path: 'schluessel/private-schluessel',
    component: PrivateKeyListComponent,
    canActivate: [isAuthenticated]
  },
  {
    path: 'schluessel/private-schluessel/neu',
    component: PrivateKeyFormComponent,
    canActivate: [isAuthenticated]
  },
  {
    path: 'schluessel/private-schluessel/:id',
    component: PrivateKeyShowComponent,
    canActivate: [isAuthenticated]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(privateKeyRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PrivateKeyRoutingModule { }
