import { NextaBaseList } from '../shared/nexta-crud/nexta-base-list';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../shared/notification.service';
import { ClientService } from './crud.service';
import { Client } from './client';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './client-list.component.html'
})
export class ClientListComponent extends NextaBaseList<Client> {

  constructor(
    router: Router,
    clientService: ClientService,
    notificationService: NotificationService,
    route: ActivatedRoute,
    cdr: ChangeDetectorRef
  ) {
    super('/clients', router, clientService, notificationService, route, 'clients', cdr);
  }
}
