import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NextaSelectionBaseReactiveForm } from '../../shared/nexta-crud/nexta-selection-reactive-form';
import { NextaFileReader } from '../../shared/nexta-file-reader';
import { NotificationService } from '../../shared/notification.service';
import { Data } from './../../shared/data';
import { CertificateService } from './crud.service';
import { Certificate } from './certificate';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './certificate-form.component.html'
})
export class CertificateFormComponent extends NextaSelectionBaseReactiveForm<Certificate> {

  file: File;

  constructor(
    router: Router,
    private certificateService: CertificateService,
    notify: NotificationService,
    route: ActivatedRoute,
    acrossPagesData: Data,
    cdr: ChangeDetectorRef) {
    super('edit', router, certificateService, notify, route, cdr, null, acrossPagesData);
  }

  initFormGroup() {
    const fb = new UntypedFormBuilder();
    this.form = fb.group({
      id: [''],
      file: ['', Validators.required]
    });
  }

  transform(): any {
    const formData = new FormData();
    formData.append('file', this.file);
    return formData;
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    this.file = file;
    const fileReader: NextaFileReader = new NextaFileReader(this.form, 'file');
    fileReader.readAsArrayBuffer(file);
    this.cdr.markForCheck();
  }

  /**
   * Event-Handler für das Abschicken eines Formulars.
   * @return {[type]} [description]
   */
  onSubmit() {
    this.beforeSubmit();

    this.form.updateValueAndValidity();

    if (!this.form.valid) {
      this.cdr.markForCheck();
      return;
    }

    const data = this.transform();

    // ID setzen, da diese sich nicht im Formular befindet
    if (this.route.snapshot.params['id'] !== undefined) {
      data.id = this.route.snapshot.params['id'];
    }

    this.certificateService.saveAtSpecialEndpoint(data, this.endpoint)
      .subscribe((response) => {
        if (response) {
          this.patchValue(response);
        }

        this.onSubmitSuccess(response, 'Das Zertifikat');
        this.cdr.markForCheck();
      });
  }

  navigateToOnSubmitSuccess(): void {
    this.router.navigate(['/schluessel/zertifikate', this.form.getRawValue().id]);
  }
}
