import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../shared/notification.service';
import { AuthorityService } from './crud.service';
import { Authority } from './authority';
import { NextaSelectionBaseList } from '../shared/nexta-crud/nexta-selection-base-list';
import { UntypedFormBuilder } from '@angular/forms';
import { Data } from '../shared/data';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './authority-list.component.html'
})
export class AuthorityListComponent extends NextaSelectionBaseList<Authority> {
  constructor(
    router: Router,
    authorityService: AuthorityService,
    notificationService: NotificationService,
    acrossPagesData: Data,
    route: ActivatedRoute,
    cdr: ChangeDetectorRef,
    fb: UntypedFormBuilder
  ) {
    super('/behoerden', router, authorityService, notificationService, route, acrossPagesData, 'authorities', cdr, fb);
  }
}
