import { CertificateCrudModule } from '../key/certificate/certificate.module';
import { PrivateKeyCrudModule } from '../key/private-key/private-key.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';

import { ClientService } from './crud.service';
import { ClientFormComponent } from './client-form.component';
import { ClientListComponent } from './client-list.component';
import { ClientRoutingModule } from './client-routing.module';
import { TabViewModule } from 'primeng/tabview';

const DIRECTIVES = [
  ClientFormComponent,
  ClientListComponent
];

@NgModule({
  imports: [
    SharedModule,
    TabViewModule,
    ClientRoutingModule,
    PrivateKeyCrudModule,
    CertificateCrudModule
  ],
  providers: [
    ClientService
  ],
  declarations: [
    ...DIRECTIVES
  ],
  exports: [
    ...DIRECTIVES
  ]
})
export class ClientCrudModule {
}
