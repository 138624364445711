<form
  (submit)="onSubmit()"
  [formGroup]="form"
  class="form-horizontal transfer-form"
>
  <p class="pull-right raise">
    <button class="btn btn-primary" type="submit">Speichern</button>
  </p>

  <p-tabView>
    <p-tabPanel formGroupName="main">
      <ng-template pTemplate="header">
        Allgemein
        <app-error-badge group="main"></app-error-badge>
      </ng-template>
      <div class="panel panel-default">
        <div class="panel-heading">
          Allgemeine Daten
        </div>
        <div class="panel-body">
          <app-form-group
            [group]="main"
            control="name"
            label="Name"
          >
            <input
              id="name"
              type="input"
              placeholder="Name"
              formControlName="name"
              class="form-control"
            />
          </app-form-group>
          <app-form-group
            [group]="main"
            control="description"
            label="Beschreibung"
          >
            <input
              id="description"
              type="input"
              placeholder="Beschreibung"
              formControlName="description"
              class="form-control"
            />
          </app-form-group>

        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel *ngIf="certificatesLoaded" header="Anfrage-Zertifikate">
      <ng-template pTemplate="header">
        Anfrage-Zertifikate
      </ng-template>
      <app-certificate-selection-list
        title="Anfrage-Zertifikate"
        [data]="certificates"
        [storageData]="getStorageData('allowedCerts')"
        (addedCertificateSelected)="addSelectedCertificate($event)"
        (removeCertificateSelected)="removeSelectedCertificate($event)"
        (clickOnNew)="setClickOnNew()"
      ></app-certificate-selection-list>
    </p-tabPanel>
  </p-tabView>
</form>
