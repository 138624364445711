import { ModuleWithProviders, NgModule } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { BaseListInfoComponent } from './base-list-info.component';
import { NextaCanDeactivateCrudForm } from './nexta-can-deactivate.guard';

@NgModule({
  imports: [
    DialogModule
  ],
  declarations: [
    BaseListInfoComponent
  ],
  exports: [
    BaseListInfoComponent
  ]
})
export class NextaCrudModule {
  static forRoot(): ModuleWithProviders<NextaCrudModule> {
    return {
      ngModule: NextaCrudModule,
      providers: [
        NextaCanDeactivateCrudForm
      ]
    }
  }
}
