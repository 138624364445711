import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CrudHelperService } from '@kdose/ng-crud';
import { NotificationService } from '../shared/notification.service';
import { BaseCrudService } from '../shared/base-crud.service';

@Injectable()
export class AuthorityService extends BaseCrudService {

  constructor(helperService: CrudHelperService,
    notifyService: NotificationService,
    router: Router,
    http: HttpClient
  ) {
    super('authorities', helperService, notifyService, router, http);
  }
}
