import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CrudEntity, CrudHelperService } from '@kdose/ng-crud';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { baseCrudUrlGetter } from '../../config-getter';
import { NotificationService } from '../../shared/notification.service';
import { BaseCrudService } from '../../shared/base-crud.service';
import { PrivateKey, PrivateKeyDetails } from './private-key';

@Injectable()
export class PrivateKeyService extends BaseCrudService {

  constructor(helperService: CrudHelperService,
    notifyService: NotificationService,
    router: Router,
    http: HttpClient
  ) {
    super('private_keys', helperService, notifyService, router, http);
  }

  /**
   * Speichern (create und update) eine Entität. Hier wird der Custom-Content-Type verwendet.
   * @param  {any} entity Zu speichernde Entität.
   * @param  {any} id ID der zu speichernden Entität.
   * @return {Rx.Observable} hot Observable
   */
  saveAtSpecialEndpoint(entity: PrivateKey,endpoint: string): Observable<PrivateKey> {
    return this.post(entity, endpoint);
  }

  /**
   * Speichern eines noch nicht vorhandenen privaten Schlüssels.
   * @param  {any}                  entity Zu speichernde Entität.
   * @return {Observable<Response>}        hot Observable
   */
  post<T extends CrudEntity>(entity: T, endpoint: string): Observable<T> {
    return from(baseCrudUrlGetter()).pipe(
      mergeMap(baseUrlAsync => {
        let headers = new HttpHeaders();
        headers = headers.set('X-KDO-NO-CONTENT-TYPE', '');
        return this.http.post<T>(baseUrlAsync + this.endpoint + (endpoint ? '/' + endpoint : ''), entity, { headers })
      })
    );
  }

  /**
   * Holt die Details zu einem privaten Schlüssel
   * @param id
   */
  getDetails(id: number): Observable<PrivateKeyDetails> {
    return this.get(id, 'details');
  }
}
