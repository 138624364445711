import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';
import { Data } from './../../shared/data';
import { NextaSelectionBaseList } from './../../shared/nexta-crud/nexta-selection-base-list';
import { PrivateKeyService } from './crud.service';
import { PrivateKey } from './private-key';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-private-key-list',
  templateUrl: './private-key-list.component.html'
})
export class PrivateKeyListComponent extends NextaSelectionBaseList<PrivateKey> implements OnInit {
  constructor(
    router: Router,
    privateKeyService: PrivateKeyService,
    notificationService: NotificationService,
    route: ActivatedRoute,
    cdr: ChangeDetectorRef,
    acrossPagesData: Data,
    fb: UntypedFormBuilder
  ) {
    super('private-schluessel', router, privateKeyService, notificationService, route, acrossPagesData, 'private_keys', cdr, fb);
  }
}
