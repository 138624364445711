import { Data } from './../data';
import { OnInit, Directive, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NextaReactiveForm } from './nexta-reactive-form';
import { BaseCrudService } from '../base-crud.service';
import { Observable } from 'rxjs';
import { NotificationService } from '../notification.service';
import { CrudEntity, FilterOptions, PagedServerResponse } from '@kdose/ng-crud';
/**
 * Basisklasse für eine Form-Component.
 */
@Directive()
export abstract class NextaSelectionBaseReactiveForm<T extends CrudEntity> extends NextaReactiveForm<T> implements OnInit {

  // Verwendung als Modal-Select
  dataOutput: any;

  constructor(
    endpoint: string,
    router: Router,
    crudService: BaseCrudService,
    notify: NotificationService,
    route: ActivatedRoute,
    cdr: ChangeDetectorRef,
    protected storageRoute?: string,
    protected acrossPagesData?: Data) {
    super(endpoint, router, crudService, notify, route, cdr);
  }

  ngOnInit() {
    // Edit
    if ((!this.acrossPagesData || this.acrossPagesData.storage.length === 0)
      && typeof this.route.snapshot.params['id'] !== 'undefined') {
      this.crudService.get(+this.route.snapshot.params['id'])
        .subscribe((res) => {
          if (res.status === 404) {
            window.history.back();
            this.notify.notify('Element wurde nicht gefunden.', 'warning');
            return;
          }

          this.patchValue(res);
          this.onDataLoaded();
          this.loadSelectedData();
          this.cdr.markForCheck();
        });
    } else {
      this.loadSelectedData();
      this.cdr.markForCheck();
    }
  }

  protected doRequest(options: FilterOptions): Observable<PagedServerResponse <T>> {
    return this.crudService.getAll(options);
  }

  onSubmitSuccess(response: T, itemLabel = 'Der Eintrag'): void {
    super.onSubmitSuccess(response);
    this.notify.notify(`${itemLabel} wurde erfolgreich gespeichert.`, 'info');

    if (this.acrossPagesData && this.acrossPagesData.storage.length > 0) {
      const storageData = this.acrossPagesData.getLatest();
      storageData.updateElement = response;
      if (storageData['id']) {
        this.router.navigate([storageData['route'], storageData['id']]);
      } else {
        this.router.navigate([storageData['route'] + '/neu']);
      }
      this.form.reset();
      this.cdr.markForCheck();
    } else {
      this.navigateToOnSubmitSuccess();
      this.cdr.markForCheck();
    }
  }

  abstract navigateToOnSubmitSuccess();

  /**
   * Methode, welche überprüft, ob ein Springen von Seite A zu B zu A stattfand, um auf Seite B ein neues
   * Objekt zu erstellen. Dann wurden vorher die bereits eingegebenen Daten auf Seite A im acrossDataStorage
   * gespeichert, die nach der Rückkehr wieder geladen werden.
   */
  loadSelectedData() {
    if (this.acrossPagesData?.storage.length > 0 &&
      (this.acrossPagesData.getLatest().updateElement ||
        this.acrossPagesData.getLatest().route === this.storageRoute)) {
      const elem = this.acrossPagesData.storage.pop();
      const elementObject = JSON.parse(elem['object']);
      const { elementName, updateElement } = elem;
      if (elem.updateElement) {
        elementObject[elementName] = this.updateSelectedData(elementObject[elementName], updateElement);
      }
      this.patchValue(elementObject);
      this.onDataLoaded();
    }
  }

  protected updateSelectedData<T>(_current: T|T[], updated: T): T|T[] {
    return updated;
  }

  setClickOnNew() {
    this.clickOnNew = true;
    this.cdr.markForCheck();
  }
}
