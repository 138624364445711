import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';

export type Severity = 'success' | 'info' | 'warning' | 'error';
export namespace Severity {
  export const success = 'success';
  export const info = 'info';
  export const warning = 'warning';
  export const error = 'error';
}

export interface ConfirmOptions {
  header?: string;
  key?: string;
  message: string;
}

@Injectable()
export class NotificationService {
  constructor(private sanitizer: DomSanitizer, private messageService: MessageService, private confirmationService: ConfirmationService) {
  }

  confirm(message: string, callback: (result: boolean) => void)
  confirm(options: ConfirmOptions, callback: (result: boolean) => void)
  confirm(message: string | ConfirmOptions, callback: (result: boolean) => void) {
    let options = typeof message === 'string' ? { message } : message;
    options = Object.assign( { header: 'Sind Sie sicher?', key: 'main-danger' }, options );

    this.sanitizer.sanitize(SecurityContext.HTML, options.message);

    this.confirmationService.confirm({
      accept: () => callback(true),
      reject: () => callback(false),
      ...options
    });
  }

  notify(message: string, severity: Severity = Severity.info, targetUrl?: string) {
    this.sanitizer.sanitize(SecurityContext.HTML, message);
    const config = {
      detail: message,
      severity,
      key: 'main'
    };
    if (targetUrl) {
      config.key = 'main-link';
      config['targetUrl'] = targetUrl;
    }
    this.messageService.add( config );
  }

  alert(message: string, severity: Severity = Severity.warning ) {
    this.sanitizer.sanitize(SecurityContext.HTML, message);
    this.messageService.add({
      detail: message,
      key: 'main-sticky',
      severity
    });
  }

  acknowledgeRequired(summary: string, detail: string, severity: Severity = Severity.warning) {
    this.messageService.add({ summary, detail, severity, key: 'main-sticky' });
  }
}
