import { Component, Input, OnInit, Host, SkipSelf, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup, ControlContainer, UntypedFormBuilder } from '@angular/forms';
import { DateTime } from 'luxon';
import { SelectItem } from 'primeng/api';
import { componentParentFactory } from '../shared/component-parent.factory';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-search-column',
  styleUrls: ['./search-column.component.scss'],
  templateUrl: './search-column.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useFactory: componentParentFactory,
    deps: [SearchColumnComponent]
  }]
})
/**
 * Wrapper für Eingabefeld in der Filtermaske
 *
 * Beispielverwendung:
 *
 * <th class="search-column"><app-search-column placeholderValue="ID" formGroupNameValue="id"></app-search-column></th>
 *
 */
export class SearchColumnComponent implements OnInit {
  @Input() placeholderValue: string;
  @Input() formGroupNameValue: string;
  @Input() public data: any;
  @Input() elementType = 'string';

  availableFilterTypes: SelectItem[] = [];
  yearRange: string;

  readonly booleanSelectItems: SelectItem[] = [
    {value: '', label: ''},
    {value: 'Ja', label: 'Ja'},
    {value: 'Nein', label: 'Nein'},
  ];

  group: UntypedFormGroup;

  constructor(@Host() @SkipSelf() public parent: ControlContainer, private form: FormGroupDirective, private cdr: ChangeDetectorRef) {
    const fb = new UntypedFormBuilder();
    this.group = fb.group({
      filterType: ['='],
      filterValue: ['']
    });
    this.calculateYearRange();
  }

  ngOnInit() {
    if (!this.form.form.get(this.formGroupNameValue)) {
      this.form.form.addControl(this.formGroupNameValue, this.group);
    }
    if (this.data) {
      let concatAttributeKey = '';
      let concatAttribute = '';
      // Wert des Eingabefeldes kann serverseitig aus mehreren Attributen bestehen bspw. bei authorityId aus praefix und kennung
      for (const i of this.data as Array<any>) {
        // Holt sich die kompletten Feldnamen und schneidet das letzte Stück ab, da das zusammengesetzte Attribut diesen Teil
        // später nicht hat (bswp. bei authorityId.praefix wird das praefix abgeschnitten)
        const attributeName = (i.property as string);
        const concatAllButNotLast = attributeName.slice(0, attributeName.lastIndexOf('.'));
        // Zusammengesetztes Attribut
        if (concatAllButNotLast === this.formGroupNameValue) {
          // Fall für die Behördenkennung
          if (concatAllButNotLast.endsWith('authorityId') || concatAllButNotLast.endsWith('sender') ||
            concatAllButNotLast.endsWith('receiver')) {
            concatAttributeKey = concatAllButNotLast;
            // Wird durch ein : getrennt
            concatAttribute = concatAttribute.concat(concatAttribute.length > 0 ? ':' + i.value : i.value);
            this.form.form.get([concatAllButNotLast]).get('filterType').patchValue(i.operator);
            // Fall für Standard
          } else if (concatAllButNotLast.endsWith('standard')) {
            concatAttributeKey = concatAllButNotLast;
            // Wird durch ein Leerzeichen getrennt
            concatAttribute = concatAttribute.concat(concatAttribute.length > 0 ? ' ' + i.value : i.value);
            this.form.form.get([concatAttributeKey]).get('filterType').patchValue(i.operator);
          } else {
            // Alle anderen
            this.form.form.get(i.property).get('filterValue').patchValue(i.value);
            this.form.form.get(i.property).get('filterType').patchValue(i.operator);
          }
          // Einfaches Attribut, welches nicht aus mehreren zusammengesetzt ist.
        } else if (i.property === this.formGroupNameValue) {
          if (this.elementType === 'date') {
            if ( i.filterValue instanceof Date ) {
              this.form.form.get([i.property]).get('filterValue').patchValue(i.filterValue);
            } else {
              this.form.form.get([i.property]).get('filterValue').patchValue(DateTime.fromFormat(i.filterValue, 'dd.MM.yyyy HH:mm:ss').toJSDate());
            }
          } else {
            this.form.form.get([i.property]).get('filterValue').patchValue(i.value);
          }
          this.form.form.get([i.property]).get('filterType').patchValue(i.operator);
        }
      }
      if (concatAttribute) {
        this.form.form.get([concatAttributeKey]).get('filterValue').patchValue(concatAttribute);
      }
    }
    if (this.elementType === 'nn') {
      this.group.controls.filterType.patchValue('nn');
    }
    this.availableFilterTypes = this.getAvailableFilterTypes();
    this.cdr.markForCheck();
  }

  private getAvailableFilterTypes(): SelectItem[] {
    const result: SelectItem[] = [];
    if (this.elementType === 'nn') {
      result.push( {value: 'nn', label: 'Not null'} );
    } else {
      result.push( {value: '=', label: '=', title: 'Gleich'} );
    }

    if (this.elementType === 'uri' || this.elementType === 'string' ) {
      result.push( {value: ':', label: ':', title: 'Enthält'} );
    }

    if (this.elementType === 'number' || this.elementType === 'date' ) {
      result.push( {value: '<', label: '<', title: 'Kleiner'} );
      result.push( {value: '>', label: '>', title: 'Größer'} );
    }
    return result;
  }

  private calculateYearRange(): void {
    const today = DateTime.now();
    const start = today.minus( { years: 5 } ).toFormat('yyyy');
    const end = today.plus( { years: 15 }).toFormat('yyyy');
    this.yearRange = `${start}:${end}`;
  }
}
