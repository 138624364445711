import { Location } from '@angular/common';
import {
  HttpErrorResponse, HttpHandler, HttpHeaderResponse, HttpInterceptor,
  HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../shared/notification.service';

export const SKIP_HTTP_ERRORS = 'SKIP_HTTP_ERRORS';

/**
 * `HttpInterceptor` für das Fehlerhandling.
 */
@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {

  constructor(
    private notify: NotificationService,
    private router: Router,
    private location: Location
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent
    | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    const skipHttpErrors: number[] = [];
    const shouldSkipErrors = request.headers.has(SKIP_HTTP_ERRORS);

    if (shouldSkipErrors) {
      const skipErrors = request.headers.getAll(SKIP_HTTP_ERRORS);
      skipErrors.forEach((skip) => {
        const status = parseInt(skip, 10);
        if (!isNaN(status)) {
          skipHttpErrors.push(status)
        }
      });
      request = request.clone({ headers: request.headers.delete(SKIP_HTTP_ERRORS)});
    }

    return next.handle(request).pipe(
      catchError(err => {
        if (!err) {
          return;
        }

        if (!(err instanceof HttpErrorResponse)) {
          this.notify.notify('Es ist ein unbekannter Fehler aufgetreten.', 'error');
          return throwError(err);
        }

        const errorMessage = (<HttpErrorResponse>err).error?.message ?? err.message ?? '';

        const urlTree = this.router.createUrlTree(['monitoring'], { fragment: 'logfile' });
        const targetUrl = this.location.prepareExternalUrl(this.router.serializeUrl(urlTree));

        if (skipHttpErrors.includes(err.status)) {
          return throwError(err);
        }

        switch ((<HttpErrorResponse>err).status) {
          case 400:
            this.notify.notify(`Die Anfrage war ungültig. Klicken für Details. ${errorMessage}.`, 'error', targetUrl);
            break;
          case 401:
            this.notify.notify('Ihre Sitzung ist abgelaufen.', 'error');
            this.router.navigate(['/login']);
            break;
          case 403:
            this.notify.notify(`Sie besitzen nicht die notwendigen Rechte. ${errorMessage}.`, 'error');
            break;
          case 404:
            this.notify.notify(`Das Ziel wurde nicht gefunden. ${errorMessage}.`, 'error');
            window.history.back();
            break;
          case 409:
            console.log(err);
            this.notify.notify(`${err.error.error}. ${err.error.message} - Klicken für Details`, 'error', targetUrl);
            break;
          case 500:
            this.notify.notify(`Es ist ein interner Serverfehler aufgetreten. Klicken für Details. ${errorMessage}.`, 'error', targetUrl);
            break;
          default:
            this.notify.notify(`Es ist ein unbekannter Fehler aufgetreten. Klicken für Details. ${errorMessage}.`, 'error', targetUrl);
        }

        return throwError(err);
      })
    );
  }
}
