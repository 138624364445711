<div class="panel panel-default">
  <div class="panel-heading">
    <h3 class="panel-title">Zertifikatsliste <small *ngIf="loading">Lädt...</small>
      <span class="pull-right">
        <button
          (click)="setFilter($event)"
          class="btn btn-default"
          [title]="'Filter' + (showFilter ? ' ausblenden': ' einblenden')">
          <i class="fa fa-filter"></i> Filter {{ showFilter ? 'ausblenden' : 'einblenden' }}
        </button>
        <span class="heading-search">
          <input type="text" class="form-control" placeholder="Suche" (keydown)="search($event, 'subjectX500Principal', ':')">
        </span>
        <span *ngIf="!selectMode">
          <a [routerLink]="['/schluessel/zertifikate/neu']" class="btn btn-success">
            <i class="fa fa-plus"></i> Neues Zertifikat anlegen
          </a>
        </span>
      </span>
    </h3>
  </div>

  <form (submit)="onFilter(false)" [formGroup]="form">
    <table class="table table-striped" *ngIf="entities.length > 0 || showFilter">
      <thead>
      <tr>
        <th>ID</th>
        <th>
          <a (click)="toggleSort('serialNumber')" [class]="'sort-' + getSortingDir('serialNumber')">Seriennummer (dez/hex)</a>
        </th>
        <th>
          <a (click)="toggleSort('subjectX500Principal')" [class]="'sort-' + getSortingDir('subjectX500Principal')">Antragssteller</a>
        </th>
        <th>
          <a (click)="toggleSort('issuerX500Principal')" [class]="'sort-' + getSortingDir('issuerX500Principal')">Aussteller</a>
        </th>
        <th>
          <a (click)="toggleSort('validFrom')" [class]="'sort-' + getSortingDir('validFrom')">Gültig von</a>
        </th>
        <th>
          <a (click)="toggleSort('validTo')" [class]="'sort-' + getSortingDir('validTo')">Gültig bis</a>
        </th>
        <th class="right-align">Aktionen</th>
      </tr>
      <tr *ngIf="showFilter">
        <th class="search-column"><app-search-column elementType="number" placeholderValue="ID" formGroupNameValue="id" [data]="filter"></app-search-column></th>
        <th class="search-column"><app-search-column placeholderValue="Seriennummer (nur dez)" formGroupNameValue="serialNumber" [data]="filter"></app-search-column></th>
        <th class="search-column"><app-search-column placeholderValue="Antragssteller" formGroupNameValue="subjectX500Principal" [data]="filter"></app-search-column></th>
        <th class="search-column"><app-search-column placeholderValue="Aussteller" formGroupNameValue="issuerX500Principal" [data]="filter"></app-search-column></th>
        <th class="search-column"><app-search-column elementType="date" formGroupNameValue="validFrom" [data]="filter"></app-search-column></th>
        <th class="search-column"><app-search-column elementType="date" formGroupNameValue="validTo" [data]="filter"></app-search-column></th>
        <th class="search-column filter-buttons right-align">
          <button class="btn btn-default btn-sm" type="submit" title="Filtern"><i class="fa fa-filter"></i></button>
          <button class="btn btn-default btn-sm" (click)="onFilter(true)" type="button" title="Filter aufheben"><i class="fa fa-ban"></i></button>
          <app-base-list-info></app-base-list-info>
        </th>
      </tr>

    </thead>
    <tbody>
      <tr *ngFor="let b of entities">
        <td>
          <a [routerLink]="['/schluessel/zertifikate', b.id]">{{ b.id }}</a>
        </td>
        <td>
          {{ b.serialNumber }} / {{ b.serialNumberInHex }}
        </td>
        <td>
          <div *ngFor="let entry of (b.subjectX500Principal | principalName: true)">
            {{ entry }}
          </div>
        </td>
        <td>
          <div *ngFor="let entry of (b.issuerX500Principal | principalName: true)">
            {{ entry }}
          </div>
        </td>
        <td>
          {{ b.validFrom }}
        </td>
        <td>
          {{ b.validTo }}
        </td>
        <td class="right-align">
          <a (click)="onDelete($event, b)" href="#" class="btn btn-danger btn-sm"  *ngIf="!selectMode">
            <i class="fa fa-trash-o"></i>
          </a>
          <button
            (click)="select($event, b)"
            class="btn btn-primary btn-sm"
            *ngIf="selectMode"
            title="Verwenden">
            <i class="fa-check fa"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </form>

  <div class="panel-body" *ngIf="!loading && entities.length === 0">
      <div class="alert alert-info">
          Es wurden keine Einträge gefunden.
      </div>
  </div>

  <div class="panel-footer" *ngIf="entities.length > 0">
    <div class="row">
      <div class="col-sm-offset-6 col-sm-6">
        <p-paginator currentPageReportTemplate="Zeige Einträge {first} bis {last} von {totalRecords}."
          [showCurrentPageReport]="true" styleClass="pull-right" [rows]="size"
          [rowsPerPageOptions]="[10,20,50,100,200,{showAll:'Alle'}]" [totalRecords]="totalsize"
          (onPageChange)="handlePageChange($event)"></p-paginator>
      </div>
    </div>
  </div>
</div>
