import { NextaCanDeactivateCrudForm } from './../shared/nexta-crud/nexta-can-deactivate.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthenticated } from '@kdose/ng-auth';
import { AuthorityListComponent } from './authority-list.component';
import { AuthorityFormComponent } from './authority-form.component';

const authorityRoutes: Routes = [
  {
    path: 'behoerden',
    component: AuthorityListComponent,
    canActivate: [isAuthenticated]
  },
  {
    path: 'behoerden/neu',
    component: AuthorityFormComponent,
    canDeactivate: [NextaCanDeactivateCrudForm],
    canActivate: [isAuthenticated]
  },
  {
    path: 'behoerden/:id',
    component: AuthorityFormComponent,
    canDeactivate: [NextaCanDeactivateCrudForm],
    canActivate: [isAuthenticated]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(authorityRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AuthorityRoutingModule { }
