import { NextaBaseList } from '../shared/nexta-crud/nexta-base-list';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../shared/notification.service';
import { UserService } from './crud.service';
import { User } from './user';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-list.component.html'
})
export class UserListComponent extends NextaBaseList<User> {

  constructor(
    router: Router,
    userService: UserService,
    notificationService: NotificationService,
    route: ActivatedRoute,
    cdr: ChangeDetectorRef    ) {
    super('/benutzer', router, userService, notificationService, route, 'users', cdr);
  }
}
