import { Component, Input, OnChanges, AfterContentInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-group',
  template: `
  <div class="form-group" [class.has-error]="errornous">
      <div class="control-label"
           *ngIf="hasLabel"
           [class.col-xs-12]="vertical"
           [class.left-aligned-label]="vertical"
           [class.col-sm-3]="!vertical && !vertical && !biggerLabel"
           [class.col-sm-5]="!vertical && !vertical && biggerLabel"
       >
          <label [attr.for]="control" class="form-label">
              {{ label }}
          </label>
      </div>
      <div
        [class.col-xs-12]="hasLabel && vertical"
        [class.col-sm-9]="hasLabel && !vertical && !biggerLabel"
        [class.col-sm-7]="hasLabel && !vertical && biggerLabel"
      >
          <ng-content></ng-content>

          <span class="help-block" *ngIf="errRequired">Dieses Feld ist erforderlich.</span>
          <span class="help-block" *ngIf="invalidEmailAddress">
            Die E-Mail-Adresse ist ungültig.</span>
          <span class="help-block" *ngIf="invalidNumber">
              Die Zahl ist ungültig.</span>
          <span class="help-block" *ngIf="customError || pattern">{{ errorText }}</span>
      </div>
  </div>
  `
})
/**
 * Wrapper für Twitter-Bootstraps form-group.
 * Es erzeugt ebenfalls das Label für das Eingabefeld und
 * Standardfehlermeldungen für z. B. required, maxlength etc.
 *
 * Beispielverwendung:
 *
 * <form-group control="myfield" [group]="form.controls" label="Benutzername">
 *         <!-- Form-Elemente -->
 * </form-group>
 *
 */
export class FormGroupComponent implements OnChanges, AfterContentInit {
  @Input() control: string;
  @Input() group: UntypedFormGroup;
  @Input() selectionChanged: boolean;
  @Input() label: string;

  // Vergrößert das Label von col-sm-3 auf col-sm-5
  @Input() biggerLabel = false;
  // Das Label wird über dem Input-Feld platziert
  @Input() vertical = false;

  @Input() errorText: string;

  errornous: boolean;
  errRequired: boolean;
  customError: boolean;
  invalidEmailAddress: boolean;
  invalidNumber: boolean;
  pattern: boolean;

  constructor(public form: FormGroupDirective, private cdr: ChangeDetectorRef) {
    form.ngSubmit.subscribe(() => this.updateValidity());
  }

  get hasLabel(): boolean {
    return typeof this.label !== 'undefined';
  }

  get hasControl(): boolean {
    return typeof this.control !== 'undefined' &&
      typeof this.group !== 'undefined' &&
      typeof this.group.controls !== 'undefined' &&
      this.group.controls.hasOwnProperty(this.control);
  }

  ngAfterContentInit() {
    this.updateValidity();

    if (this.hasControl) {
      this.group.get(this.control).valueChanges.subscribe(() => this.updateValidity());
    }
  }

  ngOnChanges() {
    this.updateValidity();
  }

  hasError(error = ''): boolean {
    return this.hasControl && (
      (this.form.submitted || this.selectionChanged) &&
      (error ? this.group.get(this.control).hasError(error) :
        !this.group.get(this.control).valid)
    );
  }

  private updateValidity() {
    this.errornous = this.hasError();
    this.errRequired = this.hasError('required');
    this.customError = this.hasError('customError');
    this.invalidEmailAddress = this.hasError('invalidEmailAddress');
    this.invalidNumber = this.hasError('invalidNumber');
    this.pattern = this.hasError('pattern');
    this.cdr.markForCheck();
  }
}
