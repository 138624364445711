function ensureEndsWith(value: string, endsWith: string) {
  return value.endsWith(endsWith) ? value : value + endsWith;
}

class AppSettings {
  /**
   * Zwischenspeicher der bereits geladenen Basis-URL.
   * Sollte nie direkt verwendet werden. Stattdessen immer über den
   * `baseUrlGetter()` arbeiten.
   */
  static baseUrl: string;
}

export function baseUrlGetter(): Promise<string> {
  return new Promise((resolve) => {
    if (AppSettings.baseUrl) {
      resolve(AppSettings.baseUrl);
      return;
    }

    const base = document.querySelector('base');
    const baseUrl = ensureEndsWith(base && base.href || '', '/');

    fetch(baseUrl + 'config.json', { method: 'get' })
      .then(res => res.json())
      .then(url => {
        AppSettings.baseUrl = ensureEndsWith( url.restUrl, '/' );
        resolve(AppSettings.baseUrl);
      })
      .catch(() => {
        const url = location.protocol + '//' + location.host + '/';
        AppSettings.baseUrl = url;
        resolve(url);
      });
  });
}

export function baseCrudUrlGetter(): Promise<string> {
  return new Promise(resolve =>
    (baseUrlGetter() as Promise<string>)
      .then(val => resolve(val + 'rest/'))
  );
}
