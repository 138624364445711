import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthenticated } from '@kdose/ng-auth';
import { EndPointListComponent } from './end-point-list.component';

const endPointsRoutes: Routes = [ {
    path: 'routen',
    component: EndPointListComponent,
    canActivate: [isAuthenticated]
  } ];
@NgModule({
  imports: [
    RouterModule.forChild(endPointsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class EndPointRoutingModule { }
