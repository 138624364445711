import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { NextaSelectionBaseReactiveForm } from '../shared/nexta-crud/nexta-selection-reactive-form';
import { Data } from './../shared/data';
import { AuthorityService } from './crud.service';
import { EndPointsComponent } from './end-points.component';
import { EndPoint } from '../endPoint/end-point';
import { NotificationService } from '../shared/notification.service';
import { Authority } from './authority';
import { Client } from '../client/client';
import { ClientService } from '../client/crud.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
  styleUrls: ['./authority-form.component.scss'],
  templateUrl: './authority-form.component.html'
})
export class AuthorityFormComponent extends NextaSelectionBaseReactiveForm<Authority> {
  clients: Client[] = [];
  data: any;
  endPoints: EndPoint[] = [];

  get main(): UntypedFormGroup {
    return this.form.get('main') as UntypedFormGroup || null;
  }

  private fb: UntypedFormBuilder;

  constructor(
    router: Router,
    crudService: AuthorityService,
    notify: NotificationService,
    route: ActivatedRoute,
    acrossPagesData: Data,
    cdr: ChangeDetectorRef,
    private dialog: DialogService,
    private clientService: ClientService
  ) {
    super('behoerden', router, crudService, notify, route, cdr, '/behoerden', acrossPagesData);
    this.loadClients();
  }

  private loadClients() {
    this.clientService
      .getAll({size: 9999, sort: JSON.stringify({ active: 'name', direction: 'asc' })})
      .subscribe(data => {
        this.clients = data.content;
        this.cdr.markForCheck();
      });
  }

  initFormGroup() {
    this.fb = new UntypedFormBuilder();
    this.form = this.fb.group({
      main: this.fb.group({
        id: [''],
        active: [''],
        client: ['', Validators.required],
        defaultRoute: [''],
        prefix: ['', Validators.required],
        identifier: ['', Validators.required],
        lastUpdate: [''],
        entityVersion: [''],
        target: this.fb.group({
          to: ['', Validators.required],
          xta1Port: ['', Validators.required],
          xta2SendPort: '',
          xta2MsgboxPort: '',
          xta2ManagePort: '',
        })
      })
    });
    this.getAllEndPoints();
  }

  getAllEndPoints() {
    this.doRequest({
      page: null,
      size: null,
      sort: JSON.stringify({ active: 'prefix', direction: 'desc' }),
      filter: null
    }).subscribe(res => {
      res.content.forEach(auth => {
        const endpoint = { url: auth.to, defaultRoute: auth.defaultRoute } as EndPoint;

        const compareResult = this.endPoints.findIndex(item => item.url === endpoint.url);

        if (compareResult === -1) {
          this.endPoints.push(endpoint);
        } else if (compareResult !== -1) {
          if (endpoint.defaultRoute === true) {
            this.endPoints[compareResult].defaultRoute = true;
          }
        }
      });
      this.cdr.markForCheck();
    });
  }

  patchValue(data: any) {
    const authority = { main: data };
    this.data = data;
    this.form.patchValue(authority);
  }

  transform() {
    const authority = Object.assign({}, this.form.value.main);
    return authority;
  }

  beforeSubmit(): void {
    if (!this.form.value.main.key.id) {
      (this.form.get(['main', 'key']) as UntypedFormGroup).setErrors({
        required: false
      });
    }
  }

  navigateToOnSubmitSuccess() {
    this.router.navigate(['/behoerden', this.form.getRawValue().main.id]);
  }

  getStorageData(elementName: string): any {
    return {
      route: this.storageRoute,
      id: this.form.get('main.id').value,
      object: JSON.stringify(this.transform()),
      elementName
    };
  }

  openEndPunktListeModal() {
    const config: DynamicDialogConfig = {
      closable: true,
      data: this.endPoints,
      dismissableMask: true,
      header: 'Endpunkte - Liste'
    };

    const ref = this.dialog.open(EndPointsComponent, config);
    ref.onClose.subscribe((data: string) => {
      if (data) {
        this.toUrl.setValue(data);
      }
      this.cdr.markForCheck();
    });
  }

  loadDefaultRoute() {
    this.endPoints.forEach((endPoint: EndPoint) => {
      if (endPoint.defaultRoute === true) {
        this.toUrl.setValue(endPoint.url)
      }
    });
    this.cdr.markForCheck();
  }

  get toUrl(): UntypedFormControl {
    return this.form.controls.main.get('target').get('to') as UntypedFormControl;
  }
}
