import { NgModule } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { CertificateCrudModule } from './../key/certificate/certificate.module';
import { PrivateKeyCrudModule } from './../key/private-key/private-key.module';
import { SharedModule } from '../shared';

import { AuthorityService } from './crud.service';
import { AuthorityFormComponent } from './authority-form.component';
import { AuthorityListComponent } from './authority-list.component';
import { AuthorityRoutingModule } from './authority-routing.module';
import { EndPointsComponent } from './end-points.component';

const DIRECTIVES = [
  AuthorityFormComponent,
  AuthorityListComponent,
  EndPointsComponent
];

@NgModule({
    imports: [
        SharedModule,
        TabViewModule,
        AuthorityRoutingModule,
        PrivateKeyCrudModule,
        CertificateCrudModule,
    ],
    providers: [
        AuthorityService
    ],
    declarations: [
        ...DIRECTIVES
    ],
    exports: [
        ...DIRECTIVES
    ]
})
export class AuthorityCrudModule {
}
