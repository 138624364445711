import { NextaCanDeactivateCrudForm } from '../shared/nexta-crud/nexta-can-deactivate.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthenticated } from '@kdose/ng-auth';
import { ClientListComponent } from './client-list.component';
import { ClientFormComponent } from './client-form.component';

const clientRoutes: Routes = [
  {
    path: 'clients',
    component: ClientListComponent,
    canActivate: [isAuthenticated]
  },
  {
    path: 'clients/neu',
    component: ClientFormComponent,
    canDeactivate: [NextaCanDeactivateCrudForm],
    canActivate: [isAuthenticated]
  },
  {
    path: 'clients/:id',
    component: ClientFormComponent,
    canDeactivate: [NextaCanDeactivateCrudForm],
    canActivate: [isAuthenticated]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(clientRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ClientRoutingModule { }
