import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EndPoint } from '../endPoint/end-point';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    '.success_default_route {background-color: #b7e0c7;border-color: #b7e0c7;}'
  ],
  template: `
    <div class="panel panel-default">
      <table class="table table-striped" *ngIf="endPoints?.length">
        <thead>
          <tr>
            <th>
              Endpunkt-URL
            </th>
            <th>
              Default-Route
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let endPoint of endPoints"
            [class.success_default_route]="endPoint['defaultRoute'] === true"
          >
            <td (click)="selectEndPoint(endPoint)">
              {{ endPoint.url }}
            </td>
            <td (click)="selectEndPoint(endPoint)" >
              <span>
                <i class="fa"
                  [class.fa-check]="endPoint.defaultRoute"
                  [class.fa-minus]="!endPoint.defaultRoute"
                  aria-hidden="true"></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  `
})
export class EndPointsComponent {
  endPoints: EndPoint[] = [];
  constructor(private ref: DynamicDialogRef, private cdr: ChangeDetectorRef, config: DynamicDialogConfig) {
    this.endPoints = config.data;
  }

  selectEndPoint(endPoint: EndPoint) {
    this.ref.close(endPoint.url);
    this.cdr.markForCheck();
  }
}
