import { OnInit, Directive, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';
import { ICrudForm } from './form.interface';
import { BaseCrudService } from '../base-crud.service';
import { CrudEntity } from '@kdose/ng-crud';

/**
 * Basisklasse für eine Form-Component.
 */
@Directive()
export abstract class NextaReactiveForm<T extends CrudEntity> implements ICrudForm, OnInit {

  public form: UntypedFormGroup;
  public submittedWithSuccess = false;

  protected cleanBeforeSave = true;

  /* Variable gibt an, ob auf einen Button für das Neuerstellen eines Objektes geklickt wurde.
   * Dies verhindert dann die Abfrage, ob die Maske gewechselt werden soll, obwohl schon ggf. Daten eingegeben wurden.
   */
  public clickOnNew = false;

  constructor(
    protected endpoint: string,
    protected router: Router,
    protected crudService: BaseCrudService,
    protected notify: NotificationService,
    protected route: ActivatedRoute,
    protected cdr: ChangeDetectorRef,
    public disableLeaveQuestion = false
  ) {
    this.initFormGroup();
  }

  ngOnInit() {
    // Edit
    if (typeof this.route.snapshot.params['id'] !== 'undefined') {
      this.crudService.get(+this.route.snapshot.params['id'])
        .subscribe((res) => {
          if (res.status === 404) {
            window.history.back();
            this.notify.notify('Element wurde nicht gefunden.', 'warning');
            return;
          }

          this.patchValue(res);
          this.onDataLoaded();
          this.cdr.markForCheck();
        });
    }
  }

  /**
   * Methode zum Setzen der Formulardaten.
   */
  patchValue(data: T): void {
    this.form.patchValue(data);
    this.cdr.markForCheck();
  }

  /**
   * Hier soll die FormGroup initialisiert werden.
   */
  abstract initFormGroup();

  /**
   * Gibt zurück, ob die Entität neu ist.
   *
   * @return {boolean} Entität neu
   */
  isNew(): boolean {
    return typeof this.route.snapshot.params['id'] === 'undefined';
  }

  /**
   * Gibt den Text "hinzufügen" oder "bearbeiten" zurück -
   * je nachdem, in welchem Stadium die Entität sich gerade befindet.
   *
   * @return {string} "hinzufügen" oder "bearbeiten"
   */
  getActionText(): string {
    return this.isNew() ? 'hinzufügen' : 'bearbeiten';
  }

  onDataLoaded(): void { }

  /**
   * Methode zum Überschreiben, um eigene Save-Success-Logik
   * zu hinderlegen.
   */
  onSubmitSuccess(_response?: T): void {
    this.submittedWithSuccess = true;
  }

  /**
   * Methode zum Überschreiben, um eigene Pre-Save-Logik
   * zu hinderlegen.
   */
  beforeSubmit(): void { }

  /**
   * Methode zum Überschreiben, um die Formulardaten zu transformieren.
   */
  transform(data: any): any {
    return data;
  }

  /**
   * Event-Handler für das Abschicken eines Formulars.
   * @return {[type]} [description]
   */
  onSubmit() {
    this.beforeSubmit();

    this.form.updateValueAndValidity();

    if (!this.form.valid) {
      this.cdr.markForCheck();
      return;
    }

    const data = this.transform(this.form.value);

    // ID setzen, da diese sich nicht im Formular befindet
    if (this.route.snapshot.params['id'] !== 'undefined') {
      data.id = this.route.snapshot.params['id'];
    }

    this.crudService.save(data, this.cleanBeforeSave).subscribe((response) => {
      this.patchValue(response);
      this.onSubmitSuccess(response);
      this.cdr.markForCheck();
    }, () => {
      this.cdr.markForCheck();
    });
}
}
