import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { NotificationService } from '../../shared/notification.service';
import { ICrudForm } from './form.interface';

/**
 * Beim Verlassen des Formulars vorher fragen, um ungesicherte Änderungen
 * zu schützen.
 */
@Injectable()
export class NextaCanDeactivateCrudForm implements CanDeactivate<ICrudForm> {
  constructor(public notify: NotificationService) { }
  canDeactivate(cmp: ICrudForm): Observable<boolean> | boolean {
    if (!cmp.disableLeaveQuestion && !cmp.form.pristine && !cmp.submittedWithSuccess && !cmp.clickOnNew) {
      return new Observable<boolean>(subscribe => {
        this.notify.confirm(
          'Sind Sie sicher, dass Sie das Formular verlassen möchten?' +
          ' Ungesicherte Änderungen gehen dann verloren.',
          answer => {
            subscribe.next(!!answer);
            subscribe.complete();
          }
        );
      });
    }

    return true;
  }
}
