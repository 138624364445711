import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';

import { KDOCrudModule } from '@kdose/ng-crud';
import { SpringBootUiModule } from '@kdose/ng-spring-boot-ui';

import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { AuthorityCrudModule } from './authority/authority.module';
import { baseCrudUrlGetter } from './config-getter';
import { CertificateCrudModule } from './key/certificate/certificate.module';
import { KeyCrudModule } from './key/key.module';
import { PrivateKeyCrudModule } from './key/private-key/private-key.module';
import { PipesModule } from './pipes/pipes.module';
import { SharedModule } from './shared';
import { Data } from './shared/data';
import { HttpErrorInterceptorService } from './shared/http-error-interceptor.service';
import { HttpInterceptorService } from './shared/http-interceptor.service';
import { HTTP_OPTIONS } from './shared/http-options.token';
import { NextaCrudModule } from './shared/nexta-crud/crud.module';
import { UserCrudModule } from './user';
import { EndPointModule } from './endPoint';
import { environment } from '../environments/environment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationService } from './shared/notification.service';
import { ClientCrudModule } from './client';
import { KDOAuthenticationModule } from '@kdose/ng-auth';
import { KDOAuthenticationPrimeNgModule } from '@kdose/ng-auth-primeng';

// Application wide providers
const APP_PROVIDERS = [
  ConfirmationService,
  MessageService,
  NotificationService,
  Data,
  { provide: HTTP_OPTIONS, useValue: { contentType: 'application/json' } },
  { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorService, multi: true },
];

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent
  ],
  imports: [ // import Angular's modules
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, { useHash: false }),
    ConfirmDialogModule,
    ToastModule,

    SpringBootUiModule.forRoot({
      baseUrl: 'actuator',
      logfile: {
        nameTemplate: `XTA-Soap-Proxy_${environment.version}_\${luxon:yyyy-MM-dd_HH-mm-ss}.txt`
      },
      metrics: ['.']
    }),
    NextaCrudModule.forRoot(),
    SharedModule,
    PipesModule,
    CertificateCrudModule,
    PrivateKeyCrudModule,
    KeyCrudModule,
    AuthorityCrudModule,
    EndPointModule,
    UserCrudModule,
    ClientCrudModule,
    KDOAuthenticationModule.forRoot('/login', baseCrudUrlGetter()),
    KDOAuthenticationPrimeNgModule,
    KDOCrudModule.forRoot({
      config: {
        baseUrl: baseCrudUrlGetter
      }
    }),
  ],
  providers: [
    APP_PROVIDERS
  ]
})
export class AppModule {
}
