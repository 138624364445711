import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CertificateService } from '../certificate/crud.service';
import { NotificationService } from '../../shared/notification.service';
import { PrivateKeyService } from './crud.service';
import { PrivateKeyDetails } from './private-key';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./private-key-show.component.scss'],
  templateUrl: './private-key-show.component.html'
})
export class PrivateKeyShowComponent implements OnInit {

  public issuerCertificateID: string;
  public privateKeyDetails: PrivateKeyDetails;

  constructor(private crudService: PrivateKeyService,
    private cerificateService: CertificateService,
    private notify: NotificationService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef) {
  }

  /**
   * Details-Methode muss benutzt werden
   */
  ngOnInit() {
    if (typeof this.route.snapshot.params['id'] !== 'undefined') {
      this.crudService.getDetails(+this.route.snapshot.params['id'])
        .subscribe((res) => {
          this.privateKeyDetails = res;
          this.onDataLoaded();
          this.cdr.markForCheck();
        }, (error) => {
          if (error.status === 404) {
            window.history.back();
            this.notify.notify('Element wurde nicht gefunden.', 'warning');
          }
        });
    }
  }

  onDataLoaded() {
    if (this.privateKeyDetails.certificateDetails.certificate.issuerX500Principal
      !== this.privateKeyDetails.certificateDetails.certificate.subjectX500Principal) {
      this.cerificateService
        .get(this.privateKeyDetails.certificateDetails.certificate.id, 'issuer')
        .subscribe(res => {
          if (res) {
            this.issuerCertificateID = res.id;
            this.cdr.markForCheck();
          }
        });
    }
  }
}
