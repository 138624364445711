<div class="sidebar-wrapper"
  [class.sidebar-wrapper-big-hide]="!showSidenav"
  [class.sidebar-wrapper-small-show]="showSidenav"
  >
  <ul class="sidebar-nav">
    <li class="sidebar-brand">
      XTA-Soap-Proxy
    </li>
    <li>
      <p-menu [model]="menuItems"></p-menu>
    </li>
  </ul>
</div>
