import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { SearchColumnComponent } from '../search/search-column.component';
import { ErrorBadgeComponent } from './error-badge.component';
import { FormGroupComponent } from './form-group.component';
import { ModalSelectComponent } from './modal-select.component';
import { ModalComponent } from './modal/modal.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NextaCrudModule } from './nexta-crud/crud.module';

const DIRECTIVES = [
  ModalComponent,
  NavbarComponent,
  SidenavComponent,
  ErrorBadgeComponent,
  FormGroupComponent,
  SearchColumnComponent,
  ModalSelectComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    BrowserAnimationsModule,
    CalendarModule,
    DropdownModule,
    InputNumberModule,
    InputTextModule,
    MenuModule,
    PaginatorModule,
    TooltipModule,
    NextaCrudModule
  ],
  declarations: [
    ...DIRECTIVES
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    PaginatorModule,
    TooltipModule,
    NextaCrudModule,
    ...DIRECTIVES
  ]
})
export class SharedModule {
}
