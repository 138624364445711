import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../shared/notification.service';
import { NextaSelectionBaseReactiveForm } from '../shared/nexta-crud/nexta-selection-reactive-form';
import { Data } from '../shared/data';
import { UserService } from './crud.service';
import { User } from './user';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-form.component.html'
})
export class UserFormComponent extends NextaSelectionBaseReactiveForm<User> implements OnInit {

  loading = false;
  data: any;

  constructor(
    router: Router,
    crudService: UserService,
    notify: NotificationService,
    route: ActivatedRoute,
    cdr: ChangeDetectorRef,
    acrossPagesData: Data) {
    super('benutzer', router, crudService, notify, route, cdr, '/benutzer', acrossPagesData);
  }

  ngOnInit() {
    if ((!this.acrossPagesData || this.acrossPagesData.storage.length === 0) && typeof this.route.snapshot.params['id'] !== 'undefined') {
      this.crudService.get(+this.route.snapshot.params['id'])
        .subscribe((res) => {
          if (res.status === 404) {
            window.history.back();
            this.notify.notify('Element wurde nicht gefunden.', 'warning');
            return;
          }

          this.patchValue(res);
          this.onDataLoaded();
          this.cdr.markForCheck();
        });
    } else {
      this.onDataLoaded();
      this.cdr.markForCheck();
    }
    this.loadSelectedData();
  }

  initFormGroup() {
    const fb = new UntypedFormBuilder();
    this.form = fb.group({
      id: [''],
      username: ['', Validators.required],
      enabled: [''],
      password: [''],
      updatePassword: [''],
      ldapAds: [true],
      lastUpdate: [''],
      entityVersion: ['']
    });
  }

  patchValue(data: any) {
    this.data = data;
    this.form.patchValue(data);
  }

  onDataLoaded() {
    this.loading = true;
  }

  transform() {
    const user = <any>{};
    Object.assign(user, this.form.value);
    delete user.adLogin;
    return user;
  }

  beforeSubmit(): void {
    if (this.form.get('ldapAds').dirty || this.form.get('password').dirty) {
      this.form.get('updatePassword').setValue(true);
    }
  }

  onAdLoginChange() {
    const adLogin = this.form.getRawValue().ldapAds;
    const passwordControl = this.form.get('password');
    if (adLogin) {
      passwordControl.clearValidators();
      passwordControl.setValue(null);
    } else {
      passwordControl.setValidators([Validators.required]);
    }
    passwordControl.updateValueAndValidity();
    this.cdr.markForCheck();
  }

  navigateToOnSubmitSuccess() {
    this.router.navigate(['/benutzer', this.form.getRawValue().id]);
  }

  getStorageData(elementName: string): any {
    return {
      route: this.storageRoute,
      id: this.form.get('id').value,
      object: JSON.stringify(this.transform()),
      elementName
    };
  }

  /**
   * Methode, welche überprüft, ob ein Springen von Seite A zu B zu A stattfand, um auf Seite B ein neues
   * Objekt zu erstellen. Dann wurden vorher die bereits eingegebenen Daten auf Seite A im acrossDataStorage
   * gespeichert, die nach der Rückkehr wieder geladen werden.
   * Abweichend: Das Objekt was zurückgegeben wird, muss nicht zwingend zugewiesen werden, sondern aauch uf ein Array gepusht werden.
   */
  loadSelectedData() {
    if (this.acrossPagesData && this.acrossPagesData.storage.length > 0 &&
      (this.acrossPagesData.getLatest().updateElement ||
        this.acrossPagesData.getLatest()['route'] === this.storageRoute)) {
      const elem = this.acrossPagesData.storage.pop();
      const elementObject = JSON.parse(elem['object']);
      if (elem.updateElement) {
        if (elem['elementName'] === 'key') {
          elementObject[elem['elementName']] = elem['updateElement'];
        } else {
          elementObject[elem['elementName']].push(elem['updateElement']);
        }
      }
      this.patchValue(elementObject);
      this.onDataLoaded();
      this.cdr.markForCheck();
    }
  }
}
