<div class="panel panel-default">
  <div class="panel-heading">
    <h3 class="panel-title">{{ title }}
      <span *ngIf="!readonly" class="pull-right">
        <span>
          <app-modal-select
            title="Suchen und hinzufügen"
            (selected)="addedCertificateSelected.emit($event)">
            <app-certificate-list #selectComponent [selectMode]="true"></app-certificate-list>
          </app-modal-select>
          <button type="button" (click)="createNewElement()" class="btn btn-default" title="Neu anlegen und übernehmen">
            <i class="fa fa-plus"></i>
          </button>
        </span>
      </span>
    </h3>
  </div>

  <table class="table table-striped" *ngIf="data.length > 0">
    <thead>
      <tr>
        <th>ID</th>
        <th>
          Seriennummer (dez/hex)
        </th>
        <th>
          Antragssteller
        </th>
        <th>
          Aussteller
        </th>
        <th>
          Gültig von
        </th>
        <th>
          Gültig bis
        </th>
        <th class="right-align">Aktionen</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let certificate of data">
        <td>
          {{ certificate.id }}
        </td>
        <td>
          {{ certificate.serialNumber }} / {{ certificate.serialNumberInHex }}
        </td>
        <td>
          <div *ngFor="let entry of (certificate.subjectX500Principal | principalName: true)">
            {{ entry }}
          </div>
        </td>
        <td>
          <div *ngFor="let entry of (certificate.issuerX500Principal | principalName: true)">
            {{ entry }}
          </div>
        </td>
        <td>
          {{ certificate.validFrom }}
        </td>
        <td>
          {{ certificate.validTo }}
        </td>
        <td class="right-align">
          <a title="Anzeigen" [routerLink]="['/schluessel/zertifikate', certificate.id]" class="btn btn-default">
            <i class="fa fa-eye"></i>
          </a>
          <a *ngIf="!readonly"
            title="Aus Leser-Liste löschen"
            (click)="onDelete($event, certificate)"
            href="#"
            class="btn btn-danger btn-sm"
          >
            <i class="fa fa-trash-o"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="panel-body" *ngIf="data.length === 0">
    <div class="alert alert-info">
      Es wurden keine Einträge gefunden.
    </div>
  </div>
</div>
