<div class="row row-centered">
  <div class="col-xs-12 col-sm-12 col-md-6 col-centered">
    <div class="panel panel-default">
      <div class="panel-heading">
        Privaten Schlüssel ansehen
      </div>
      <div class="panel-body" *ngIf="privateKeyDetails">
        <div class="form-group">
          <div class="control-label col-sm-5">
            <label class="form-label">ID</label>
          </div>
          <div class="col-sm-7 form-control-static">{{ privateKeyDetails.id }}</div>
        </div>

        <div class="form-group">
          <div class="control-label col-sm-5">
            <label class="form-label">Algorithmus</label>
          </div>
          <div class="col-sm-7 form-control-static">{{ privateKeyDetails.algorithm }}</div>
        </div>

        <div class="form-group">
          <div class="control-label col-sm-5">
            <label class="form-label">Seriennummer (dez/hex)</label>
          </div>
          <div class="col-sm-7 form-control-static">{{ privateKeyDetails.certificateDetails.certificate.serialNumber }} /
            {{ privateKeyDetails.certificateDetails.certificate.serialNumberInHex }}</div>
        </div>

        <div class="form-group">
          <div class="control-label col-sm-5">
            <label class="form-label">Antragssteller</label>
          </div>
          <div class="col-sm-7 form-control-static word-break-word">{{ privateKeyDetails.certificateDetails.certificate.subjectX500Principal| principalName: false }}</div>
        </div>

        <div class="form-group">
          <div class="control-label col-sm-5">
            <label class="form-label">Aussteller</label>
          </div>
          <div class="col-sm-7 form-control-static word-break-word" *ngIf="!issuerCertificateID">{{ privateKeyDetails.certificateDetails.certificate.issuerX500Principal| principalName: false }}</div>
          <div class="col-sm-7 form-control-static word-break-word" *ngIf="issuerCertificateID">
            <a [routerLink]="['/schluessel/zertifikate', issuerCertificateID]">
              {{ privateKeyDetails.certificateDetails.certificate.issuerX500Principal| principalName: false }}
            </a>
          </div>
        </div>

        <div class="form-group">
          <div class="control-label col-sm-5">
            <label class="form-label">Gültig von</label>
          </div>
          <div class="col-sm-7 form-control-static">{{ privateKeyDetails.certificateDetails.certificate.validFrom }}</div>
        </div>

        <div class="form-group">
          <div class="control-label col-sm-5">
            <label class="form-label">Gültig bis</label>
          </div>
          <div class="col-sm-7 form-control-static">{{ privateKeyDetails.certificateDetails.certificate.validTo }}</div>
        </div>

        <div class="form-group">
          <div class="control-label col-sm-5">
            <label class="form-label">Schlüssellänge</label>
          </div>
          <div class="col-sm-7 form-control-static">{{ privateKeyDetails.certificateDetails.keyLength }}</div>
        </div>

        <div class="form-group">
          <div class="control-label col-sm-5">
            <label class="form-label">Fingerabdruck (SHA1)</label>
          </div>
          <div class="col-sm-7 form-control-static">{{ privateKeyDetails.certificateDetails.thumbprintWithSHA1 }}</div>
        </div>

        <div class="form-group">
          <div class="control-label col-sm-5">
            <label class="form-label">Schlüsselverwendungen</label>
          </div>
          <div class="col-sm-7 form-control-static word-break-word">{{ privateKeyDetails.certificateDetails.keyUsages }}</div>
        </div>

        <div class="form-group">
          <div class="control-label col-sm-5">
            <label class="form-label">Erweiterte Schlüsselverwendungen</label>
          </div>
          <div class="col-sm-7 form-control-static word-break-word">{{ privateKeyDetails.certificateDetails.extendedKeyUsages }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

