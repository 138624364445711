import { CertificateCrudModule } from '../key/certificate/certificate.module';
import { PrivateKeyCrudModule } from '../key/private-key/private-key.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';

import { UserService } from './crud.service';
import { UserFormComponent } from './user-form.component';
import { UserListComponent } from './user-list.component';
import { UserRoutingModule } from './user-routing.module';

const DIRECTIVES = [
  UserFormComponent,
  UserListComponent
];

@NgModule({
  imports: [
    SharedModule,
    UserRoutingModule,
    PrivateKeyCrudModule,
    CertificateCrudModule
  ],
  providers: [
    UserService
  ],
  declarations: [
    ...DIRECTIVES
  ],
  exports: [
    ...DIRECTIVES
  ]
})
export class UserCrudModule {
}
