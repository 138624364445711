<div class="navbar">
  <div class="container-fluid">
    <ul class="navbar-nav nav">
      <li><a (click)="toggleSidebar.next()">
        <span class="glyphicon glyphicon-menu-hamburger"></span>
      </a></li>
      <li>
        <a class="page-title"></a>
      </li>
    </ul>

    <ul class="nav navbar-nav navbar-right" *ngIf="isLoggedIn">
      <li>
        <a class="no-hover">
          <i class="fa fa-fw fa-info"></i> Version: {{ version }}
        </a>
      </li>
      <li>
        <button type="button" class="btn btn-success" (click)="menu.toggle($event)">
          <span class="glyphicon glyphicon-user"></span>&nbsp;
          {{ username }}&nbsp;
          <b class="caret"></b>
        </button>
        <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
      </li>
    </ul>
  </div>
</div>
