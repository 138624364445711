<div class="row row-centered">
  <div class="col-xs-12 col-sm-12 col-md-6 col-centered">
    <div class="panel panel-default">
      <div class="panel-heading">
        Zertifikat {{ getActionText() }}
      </div>

      <div class="panel-body">
        <form (submit)="onSubmit()" [formGroup]="form" class="form-horizontal">
          <app-form-group [biggerLabel]="true" [group]="form" label="Zertifikatsdatei" control="file">
            <div class="input-wrapper">
                Datei auswählen
                <input class="file-input file-input-hidden" type="file" name="file" (change)="onFileSelected($event)"/>
            </div>
            <div class="file-input-selection" *ngIf="file"> {{ file.name }}</div>
          </app-form-group>

          <div class="form-group">
            <div class="col-sm-offset-3 col-sm-9">
              <button class="btn btn-primary" type="submit">Speichern</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
