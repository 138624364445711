<div [formGroupName]="groupNameNew">
  <div class="panel panel-default" [class.panel-danger]="!group.valid">
    <div class="panel-heading">
      {{title}}
      <p class="pull-right">
        <a *ngIf="group.get('id').value"
          title="Anzeigen"
          [routerLink]="['/schluessel/private-schluessel', group.get('id').value]"
          class="btn btn-default">
          <i class="fa fa-eye"></i>
        </a>
        <app-modal-select
          controlName="id"
          [groupName]="groupNamePath"
          title="Suchen"
          *ngIf="!readOnly">
          <app-private-key-list #selectComponent [selectMode]="true"></app-private-key-list>
        </app-modal-select>
        <button type="button" (click)="createNewElement()" class="btn btn-default" title="Neu" *ngIf="!readOnly">
          <i class="fa fa-plus"></i>
        </button>
      </p>
    </div>
    <div class="panel-body">
      <div formGroupName="certificate" *ngIf="certificate.get('id').value">
        <app-form-group control="serialNumber" label="Seriennummer (dez/hex)">
          <p class="form-control-static">{{ certificate.get('serialNumber').value }} / {{ certificate.get('serialNumberInHex').value }}</p>
        </app-form-group>

        <app-form-group control="subjectX500Principal" label="Antragssteller">
          <p class="form-control-static">{{ certificate.get('subjectX500Principal').value | principalName: true }}</p>
        </app-form-group>

        <app-form-group control="issuerX500Principal" label="Aussteller">
          <p class="form-control-static">{{ certificate.get('issuerX500Principal').value | principalName: true }}</p>
        </app-form-group>
        <app-form-group control="validFrom" label="Gültig von">
          <p class="form-control-static">{{ certificate.get('validFrom').value  }}</p>
        </app-form-group>
        <app-form-group control="validTo" label="Gültig bis">
          <p class="form-control-static">{{ certificate.get('validTo').value }}</p>
        </app-form-group>
      </div>
      <span class="help-block" *ngIf="!group.valid">Dieses Feld ist erforderlich.</span>
    </div>
  </div>
</div>
