<form (submit)="onSubmit()" [formGroup]="form" class="form-horizontal transfer-form">
  <div class="panel panel-default">
    <div class="panel-heading">
      Benutzer
      <span class="pull-right">
        <span>
          <button class="btn btn-primary" type="submit" [disabled]="!form.valid">Speichern</button>
        </span>
      </span>
    </div>
    <div class="panel-body">
      <app-form-group control="username" label="Benutzername">
        <input
          id="username"
          type="input"
          placeholder="Benutzername"
          formControlName="username"
          class="form-control">
      </app-form-group>

      <app-form-group control="enabled" label="">
        <label>
          <input
            id="enabled"
            type="checkbox"
            formControlName="enabled"> Login aktiv?
        </label>
      </app-form-group>

      <app-form-group control="ldapAds" label="">
        <label>
          <input
            id="ldapAds"
            type="checkbox"
            (change)="onAdLoginChange()"
            formControlName="ldapAds"> Login über externes Verzeichnis?
        </label>
      </app-form-group>
      <app-form-group control="password" label="Neues Passwort eingeben" *ngIf="!form.getRawValue().ldapAds">
        <input
          id="password"
          type="password"
          placeholder="Passwort"
          formControlName="password"
          class="form-control">
      </app-form-group>
    </div>
  </div>
</form>
