<form
  (submit)="onSubmit()"
  [formGroup]="form"
  class="form-horizontal transfer-form"
>
  <p class="pull-right raise">
    <button class="btn btn-primary" type="submit">Speichern</button>
  </p>

  <p-tabView>
    <p-tabPanel formGroupName="main">
      <ng-template pTemplate="header">
        Allgemein
        <app-error-badge group="main"></app-error-badge>
      </ng-template>
      <div class="panel panel-default">
        <div class="panel-heading">
          Allgemeine Daten
        </div>
        <div class="panel-body">
          <app-form-group control="active" label="">
            <label>
              <input id="active" type="checkbox" formControlName="active" />
              Route aktiv?
            </label>
          </app-form-group>

          <div class="form-group">
            <div class="control-label col-sm-3">
              <label class="form-label">Default-Route</label>
            </div>
            <div class="col-sm-7 form-control-static">
              {{ form.get("main.defaultRoute").value === true ? "Ja" : "Nein" }}
            </div>
          </div>

          <app-form-group
            [group]="main"
            control="prefix"
            label="Präfix"
          >
            <input
              id="prefix"
              type="input"
              placeholder="Präfix"
              formControlName="prefix"
              class="form-control"
            />
          </app-form-group>
          <app-form-group
            [group]="main"
            control="identifier"
            label="Kennung"
          >
            <input
              id="identifier"
              type="input"
              placeholder="Kennung"
              formControlName="identifier"
              class="form-control"
            />
          </app-form-group>

          <app-form-group
            [group]="main"
            control="client"
            label="Client"
          >
            <p-dropdown
              dataKey="id"
              [filter]="true"
              filterBy="name"
              formControlName="client"
              [options]="clients"
              optionLabel="name"
              placeholder="Client auswählen"
              styleClass="w-100"
            ></p-dropdown>
          </app-form-group>

          <app-form-group
            *ngIf="form.get('main.client').value?.allowedCerts?.length"
            label=" "
          >
            <app-certificate-selection-list
              [data]="form.get('main.client').value?.allowedCerts"
              [readonly]="true"
              title="Zugewiesene Zertifikate"
            >
            </app-certificate-selection-list>
          </app-form-group>

          <app-form-group
            [group]="main"
            formGroupName="target"
            control="to"
            label="Endpunkt-URL"
          >
            <input
              id="to"
              type="text"
              placeholder="Endpunkt-URL"
              formControlName="to"
              class="form-control"
            />

            <button
              type="button"
              class="btn btn-default"
              (click)="loadDefaultRoute()"
              title="Default-Route"
            >
              <i class="fa fa-magic"></i>
            </button>
            <button
              type="button"
              (click)="openEndPunktListeModal()"
              class="btn btn-default"
              title="Endpunkt-List"
            >
              <i class="fa fa-search"></i>
            </button>
          </app-form-group>

          <app-form-group
            [group]="main"
            formGroupName="target"
            control="xta1Port"
            label="Xta1Port"
          >
            <input
              id="xta1Port"
              type="text"
              placeholder="xta1Port"
              formControlName="xta1Port"
              class="form-control"
            />
          </app-form-group>

          <app-form-group
            [group]="main"
            formGroupName="target"
            control="xta2SendPort"
            label="Xta2SendPort"
          >
          <input
            id="xta2SendPort"
            type="text"
            placeholder="xta2SendPort"
            formControlName="xta2SendPort"
            class="form-control"
          />
          </app-form-group>

          <app-form-group
            [group]="main"
            formGroupName="target"
            control="xta2MsgboxPort"
            label="Xta2MsgboxPort"
          >
          <input
            id="xta2MsgboxPort"
            type="text"
            placeholder="xta2MsgboxPort"
            formControlName="xta2MsgboxPort"
            class="form-control"
          />
          </app-form-group>

          <app-form-group
            [group]="main"
            formGroupName="target"
            control="xta2ManagePort"
            label="Xta2ManagePort"
          >
          <input
            id="xta2ManagePort"
            type="text"
            placeholder="xta2ManagePort"
            formControlName="xta2ManagePort"
            class="form-control"
          />
          </app-form-group>

        </div>
      </div>
      <app-private-key-view
        title="Privater Schlüssel für 'Mutual SSL Certification'"
        groupName="main"
        groupNameNew="key"
        [data]="data?.key"
        [storageData]="getStorageData('key')"
        (clickOnNew)="setClickOnNew()"
      >
      </app-private-key-view>
    </p-tabPanel>
  </p-tabView>
</form>
