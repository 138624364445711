import { NextaBaseList } from './nexta-base-list';
import { Data } from './../../shared/data';
import { Input, Output, EventEmitter, OnInit, Directive, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';
import { BaseCrudService } from '../base-crud.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CrudEntity } from '@kdose/ng-crud';

@Directive()
export class NextaSelectionBaseList<T extends CrudEntity> extends NextaBaseList<T> implements OnInit {

  // Verwendung als Modal-Select
  @Input() selectMode = false;
  @Output() selected: EventEmitter<T> = new EventEmitter<T>();
  showFilter = false;
  form: UntypedFormGroup;
  isFiltered: boolean;

  constructor(routeName: string,
    router: Router,
    crudService: BaseCrudService,
    notificationService: NotificationService,
    route: ActivatedRoute,
    protected acrossPagesData: Data,
    listName: string,
    cdr: ChangeDetectorRef,
    protected fb: UntypedFormBuilder,
  ) {
    super(routeName, router, crudService, notificationService, route, listName, cdr);

    this.form = this.fb.group({});
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.selectMode) {
      this.persistStateInUrl = false;
    } else {
      this.acrossPagesData.storage = [];
    }
    this.loadEntities();
  }

  setFilter(event: Event) {
    event.preventDefault();
    this.showFilter = !this.showFilter;
    this.cdr.markForCheck();
  }

  select(evt, element: T): void {
    evt.preventDefault();
    this.selected.emit(element);
  }

  onFilter(removeAllFilter: boolean) {
    this.filter = [];
    if (!removeAllFilter) {
      const data = this.form.value;
      Object.getOwnPropertyNames(data).forEach(key => this.onFilterLogic(key, data[key]));
    }
    this.isFiltered = this.filter.length > 0;
    this.loadEntities();
  }

  onFilterLogic(key, value) {
    if (value.filterValue) {
      this.addFilter(key, value.filterValue, value.filterType, true, false);
      return;
    }

    this.removeFilter(key, false);
  }
}
