<div class="p-inputgroup" formGroupName="{{ formGroupNameValue }}">

  <p-dropdown *ngIf="availableFilterTypes?.length > 1"
    class="filter-type p-inputgroup-addon" formControlName="filterType" [options]="availableFilterTypes"></p-dropdown>
  <span *ngIf="availableFilterTypes?.length === 1" class="filter-type p-inputgroup-addon">{{availableFilterTypes[0].label}}</span>

  <input *ngIf="elementType === 'string' || elementType === 'uri' || elementType === 'enum'"
    class="filter-value"
    formControlName="filterValue"
    pInputText
    placeholder="{{ placeholderValue }}"
    type="text"
    >

  <input *ngIf="elementType === 'number'" type="number"
    class="filter-value"
    formControlName="filterValue"
    pInputNumber
    min="0"
    placeholder="{{ placeholderValue }}"
    >

  <p-calendar *ngIf="elementType === 'date'"
    class="filter-value"
    dateFormat="dd.mm.yy"
    formControlName="filterValue"
    hourFormat="24"
    [monthNavigator]="true"
    placeholder="{{ placeholderValue }}"
    [showButtonBar]="true"
    [showSeconds]="true"
    [showTime]="true"
    [yearNavigator]="true"
    [yearRange]="yearRange"
    ></p-calendar>

  <p-dropdown *ngIf="elementType === 'boolean' || elementType === 'nn' "
    [autoDisplayFirst]="false"
    class="filter-value"
    formControlName="filterValue"
    [options]="booleanSelectItems"
    placeholder="&nbsp;"
    >
  </p-dropdown>
</div>
