import { NextaCanDeactivateCrudForm } from './../../shared/nexta-crud/nexta-can-deactivate.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CertificateFormComponent } from './certificate-form.component';
import { CertificateListComponent } from './certificate-list.component';
import { CertificateShowComponent } from './certificate-show.component';
import { isAuthenticated } from '@kdose/ng-auth';

const certificateRoutes: Routes = [
  {
    path: 'schluessel/zertifikate',
    component: CertificateListComponent,
    canActivate: [isAuthenticated]
  },
  {
    path: 'schluessel/zertifikate/neu',
    component: CertificateFormComponent,
    canDeactivate: [NextaCanDeactivateCrudForm],
    canActivate: [isAuthenticated]
  },
  {
    path: 'schluessel/zertifikate/:id',
    component: CertificateShowComponent,
    canActivate: [isAuthenticated]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(certificateRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class CertificateRoutingModule { }
