import { Injectable } from '@angular/core';

@Injectable()
export class Data {
  public storage: any[] = [];

  getLatest() {
    return this.storage[this.storage.length - 1];
  }
}
