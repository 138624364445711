import { Data } from './../../shared/data';
import { NextaSelectionBaseList } from './../../shared/nexta-crud/nexta-selection-base-list';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';
import { CertificateService } from './crud.service';
import { Certificate } from './certificate';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-certificate-list',
  templateUrl: './certificate-list.component.html'
})
export class CertificateListComponent extends NextaSelectionBaseList<Certificate> {

  constructor(
    router: Router,
    certificateService: CertificateService,
    notificationService: NotificationService,
    route: ActivatedRoute,
    acrossPagesData: Data,
    fb: UntypedFormBuilder,
    cdr: ChangeDetectorRef
  ) {
    super('zertifikate', router, certificateService, notificationService, route, acrossPagesData, 'certificates', cdr, fb);
  }
}
