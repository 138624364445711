import { NextaCanDeactivateCrudForm } from '../shared/nexta-crud/nexta-can-deactivate.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthenticated } from '@kdose/ng-auth';
import { UserListComponent } from './user-list.component';
import { UserFormComponent } from './user-form.component';

const userRoutes: Routes = [
  {
    path: 'benutzer',
    component: UserListComponent,
    canActivate: [isAuthenticated]
  },
  {
    path: 'benutzer/neu',
    component: UserFormComponent,
    canDeactivate: [NextaCanDeactivateCrudForm],
    canActivate: [isAuthenticated]
  },
  {
    path: 'benutzer/:id',
    component: UserFormComponent,
    canDeactivate: [NextaCanDeactivateCrudForm],
    canActivate: [isAuthenticated]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(userRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class UserRoutingModule { }
