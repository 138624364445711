import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { NextaSelectionBaseReactiveForm } from '../shared/nexta-crud/nexta-selection-reactive-form';
import { Certificate } from './../key/certificate/certificate';
import { Data } from './../shared/data';
import { NotificationService } from '../shared/notification.service';
import { ClientService } from './crud.service';
import { ClientDetails } from './client';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
  templateUrl: './client-form.component.html'
})
export class ClientFormComponent extends NextaSelectionBaseReactiveForm<ClientDetails> {
  certificatesLoaded = false;
  data: any;

  /* Variablen für Zertifikatsansicht*/
  certificates: Certificate[] = [];

  get main(): UntypedFormGroup {
    return this.form.get('main') as UntypedFormGroup || null;
  }

  private fb: UntypedFormBuilder;

  constructor(
    router: Router,
    crudService: ClientService,
    notify: NotificationService,
    route: ActivatedRoute,
    acrossPagesData: Data,
    cdr: ChangeDetectorRef,
  ) {
    super('clients', router, crudService, notify, route, cdr, '/clients', acrossPagesData);
    this.cleanBeforeSave = false;
  }

  initFormGroup() {
    this.fb = new UntypedFormBuilder();
    this.form = this.fb.group({
      main: this.fb.group({
        id: [''],
        entityVersion: [''],
        name: ['', [Validators.required, Validators.maxLength(255)]],
        description: ['', [Validators.required, Validators.maxLength(255)]],
      })
    });
  }

  patchValue(data: any) {
    const authority = { main: data };
    this.certificates = this.ensureIsArray(data.allowedCerts);
    this.data = data;
    this.form.patchValue(authority);
    this.cdr.markForCheck();
  }

  onDataLoaded() {
    this.certificatesLoaded = true;
    this.cdr.markForCheck();
  }

  transform() {
    const allowedCerts = this.ensureIsArray(this.certificates);
    return {...this.form.value.main, allowedCerts };
  }

  navigateToOnSubmitSuccess() {
    this.router.navigate(['/clients', this.form.getRawValue().main.id]);
  }

  addSelectedCertificate(certificate: Certificate) {
    const alreadyIncluded = this.certificates.some(cert => cert.id === certificate.id);
    if (!alreadyIncluded) {
      this.certificates.push(certificate);
    }
    this.cdr.markForCheck();
  }

  removeSelectedCertificate(certificate: Certificate) {
    this.certificates = this.certificates.filter(item => item.id !== certificate.id);
    this.cdr.markForCheck();
  }

  getStorageData(elementName: string): any {
    return {
      route: this.storageRoute,
      id: this.form.get('main.id').value,
      object: JSON.stringify(this.transform()),
      elementName
    };
  }

  private ensureIsArray<T>(data?: T|T[]): T[] {
    const result = data ?? [];
    return Array.isArray(result) ? result : [result];
  }

  protected updateSelectedData<T>(current: T[], updated: T): T[] {
    if (Array.isArray(current)) {
      return [...current, updated].filter(item => !!item);
    }
    return [current, updated].filter(item => !!item);
  }
}
