import { Data } from './../../shared/data';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Host, SkipSelf } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, ControlContainer } from '@angular/forms';
import { Router } from '@angular/router';
import { NextaView } from './../../shared/nexta-crud/nexta-view';
import { componentParentFactory } from '../../shared/component-parent.factory';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-private-key-view',
  templateUrl: './private-key-view.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useFactory: componentParentFactory,
    deps: [PrivateKeyViewComponent]
  }]
})
export class PrivateKeyViewComponent extends NextaView {
  certificate: UntypedFormGroup;

  constructor(
    @Host() @SkipSelf() parent: ControlContainer,
    form: FormGroupDirective,
    router: Router,
    acrossPagesData: Data,
    cdr: ChangeDetectorRef
    ) {
    super(parent, form, router, acrossPagesData, '/schluessel/private-schluessel/neu', cdr);
  }

  initFormGroup() {
    const fb = new UntypedFormBuilder();
    this.certificate = fb.group({
      id: [''],
      encoded: [''],
      serialNumber: [''],
      serialNumberInHex: [''],
      subjectX500Principal: [''],
      issuerX500Principal: [''],
      validFrom: [''],
      validTo: ['']
    });
    this.group = fb.group({
      id: [''],
      hash: [''],
      certificate: this.certificate
    });
  }
}
