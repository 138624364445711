import { NgModule } from '@angular/core';

import { PrincipalNamePipe } from './principalNamePipe';
import { StringifyPipe } from './stringify';

const DIRECTIVES = [
  StringifyPipe,
  PrincipalNamePipe
];

@NgModule({
  declarations: [
    ...DIRECTIVES
  ],
  exports: [
    ...DIRECTIVES
  ]
})
export class PipesModule {
}
