import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

/**
 * The possible reasons a modal has been closed.
 */
export enum ModalAction { POSITIVE, CANCEL }
/**
 * Models the result of closing a modal dialog.
 */
export interface ModalResult {
  action: ModalAction;
}

/**
 * Grundaufbau: https://github.com/valor-software/ng2-bootstrap/issues/29#issuecomment-167824739
 *
 * Zeigt einen modalen Bootstrap-Dialog.
 * Den Inhalt direkt als Content im Modal-Tag verwenden:
 * <modal title="Mein Titel">
 *    Mein Inhalt
 * </modal>
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-modal',
  styleUrls: ['./modal.component.scss'],
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit {

  @Input() title: string;
  @Input() cancelLabel = 'Abbrechen';
  @Input() positiveLabel = 'OK';
  @Input() confirm = true;
  @Input() huge = true;

  /**
   * Fires an event when the modal is closed. The argument indicated how it was closed.
   * @type {EventEmitter<ModalResult>}
   */
  @Output() readonly closed = new EventEmitter<ModalResult>();
  /**
   * Fires an event when the modal is ready with a pointer to the modal.
   * @type {EventEmitter<ModalComponent>}
   */
  @Output() readonly loaded = new EventEmitter<ModalComponent>();

  showModal = false;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.loaded.next(this);
    this.cdr.markForCheck();
  }

  /**
   * Anzeigen des Modals.
   */
  public show(): void {
    this.showModal = true;
    this.cdr.markForCheck();
  }

  /**
   * Ausblenden des Modals.
   */
  public hide(): void {
    this.showModal = false;
    this.cdr.markForCheck();
  }

  positiveAction() {
    this.closed.next({ action: ModalAction.POSITIVE });
    this.hide();
  }

  cancelAction() {
    this.closed.next({ action: ModalAction.CANCEL });
    this.hide();
  }
}


