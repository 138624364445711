import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, EventEmitter, Host, Input, Output, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { CrudEntity } from '@kdose/ng-crud';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-modal-select',
  template: `
    <button (click)="showModal($event)" class="btn btn-default" title="{{ title }}">
        <i class="fa fa-search"></i>
    </button>
    <app-modal title="Objekt auswählen" [confirm]="false" #modal>
        <ng-content></ng-content>
    </app-modal>
  `
})
/**
 * Verwenden eines modalen Dialogs für Objektauswahl.
 *
 * Benötigt ein ContentChild #selectComponent, das einen EventEmitter
 * "selected" hat, bei dessen Aktivierung das hinterlegte Model und
 * Control aktualisiert wird.
 *
 * Beispielverwendung:
 *  <modal-select
 *      [(model)]="model.server"
 *      [control]="form.controls['server']"
 *      displayAttribute="name"
 *  >
 *      <server-list #selectComponent [selectMode]="true"></server-list>
 *  </modal-select>
 */
export class ModalSelectComponent<T extends CrudEntity> implements AfterContentInit {
  @Input() controlName: string;
  @Input() groupName: string[];
  @Input() title: string;
  @Output() readonly selected = new EventEmitter<T>();

  @ViewChild('modal', { static: true })
  public modal: ModalComponent;

  @ContentChild('selectComponent', { static: true })
  public selectComponent;

  constructor( @Host() @SkipSelf() public parent: ControlContainer, public form: FormGroupDirective, private cdr: ChangeDetectorRef) {
  }

  ngAfterContentInit() {
    if (!this.selectComponent) {
      throw Error('Missing #selectComponent');
    }

    if (!this.selectComponent.selected) {
      throw Error('Missing selected EventEmitter in #selectComponent');
    }

    this.selectComponent.selected.subscribe((value) => {
      this.updateModel(value);
      this.modal.hide();
      this.cdr.markForCheck();
    });
  }

  showModal(event): void {
    event.preventDefault();
    this.modal.show();
    this.cdr.markForCheck();
  }

  /**
   * Event-Listener für das Auswählen einer Entität.
   */
  private updateModel(model: T): void {
    if (this.groupName) {
      (this.form.form as UntypedFormGroup).get(this.groupName).reset();
      (this.form.form as UntypedFormGroup).get(this.groupName).patchValue(model);
      (this.form.form as UntypedFormGroup).get(this.groupName).markAsDirty();
    } else if (this.controlName) {
      (this.form.form as UntypedFormGroup).get(this.controlName).reset();
      (this.form.form as UntypedFormGroup).get(this.controlName).setValue(model[this.controlName]);
      (this.form.form as UntypedFormGroup).get(this.controlName).markAsDirty();
    }
    this.selected.emit(model);
    this.cdr.markForCheck();
  }
}
