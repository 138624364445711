import { Data } from '../../shared/data';
import { Component, EventEmitter, Output, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { Router } from '@angular/router';
import { componentParentFactory } from '../../shared/component-parent.factory';
import { Certificate } from './certificate';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-certificate-selection-list',
  templateUrl: './certificate-selection-list.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useFactory: componentParentFactory,
    deps: [CertificateSelectionListComponent]
  }]
})
export class CertificateSelectionListComponent {

  @Input() data: Certificate[];
  @Input() id: string;
  @Input() readonly = false;
  @Input() storageData: any;
  @Input() title: string;

  @Output() readonly addedCertificateSelected = new EventEmitter<Certificate>();
  @Output() readonly removeCertificateSelected = new EventEmitter<Certificate>();
  @Output() readonly clickOnNew = new EventEmitter<void>();

  constructor(private router: Router, private acrossPagesData: Data, private cdr: ChangeDetectorRef) {
  }

  createNewElement() {
    this.acrossPagesData.storage.push(this.storageData);
    this.clickOnNew.emit();
    this.router.navigate(['schluessel/zertifikate/neu']);
  }

  onDelete(event, entity: Certificate): void {
    event.preventDefault();
    this.removeCertificateSelected.emit(entity);
    this.cdr.markForCheck();
  }
}
