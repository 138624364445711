import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { baseUrlGetter } from '../../config-getter';
import { MenuItem } from 'primeng/api';
import { AuthenticationService } from '@kdose/ng-auth';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html'
})
export class NavbarComponent {
  isLoggedIn = false;
  username = '';
  version = '';

  @Output() public readonly toggleSidebar = new EventEmitter<void>();

  readonly items: MenuItem[] = [{
    label: 'Logout',
    command: () => { this.logout(); }
  }];

  constructor(
    private auth: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private router: Router
  ) {
    this.auth.userData.subscribe((userData) => {
      this.isLoggedIn = !!userData;
      this.username = userData?.username ?? '';
      this.cdr.markForCheck();
    });

    if (environment.production) {
      baseUrlGetter()
        .then((baseUrl) => {
          const headers = new HttpHeaders({
            'X-KDO-NO-AUTH': ''
          });
          const url = this.ensureEndsWith(this.merge(baseUrl, this.getBase()), '/') + 'version';
          const request = this.http.get(url, { responseType: 'text', headers });
          return firstValueFrom(request);
        }).then((version) => {
          this.version = version;
          this.cdr.markForCheck();
        });
    } else {
      this.version = 'dev-local';
      this.cdr.markForCheck();
    }
  }

  private ensureEndsWith(value: string, endsWith: string): string {
    return value.endsWith(endsWith) ? value : value + endsWith;
  }

  /**
   * Kombiniert zwei Strings, wenn der Ende von String 1 dem Anfang von String 2 entspricht.
   * Bspw. wird aus 'url.de/nexta/' und '/nexta/client/' der String 'url.de/nexta/client/'.
   * NEXTA-1110
   */
  private merge(string1, string2): string {
    let start = 0
    for (let i = 0; i < string1.length; i++) {
      if (!string2.startsWith(string1.substr(start, string1.length))) {
        start += 1;
      } else {
        break;
      }
    }
    return string1.substr(0, start) + string2
  }

  private getBase() {
    let href = '';
    const base = document.getElementsByTagName('base');
    if (base.length > 0) {
      href = base[0].getAttribute('href').substring(1);
    }
    return href;
  }

  private logout() {
    this.auth.logout();
    this.router.navigateByUrl('/login');
  }
}
