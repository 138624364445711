<div class="panel panel-default">
  <div class="panel-heading">
    <h3 class="panel-title">
      EndPunkt-Liste <small *ngIf="loading">Lädt...</small>
    </h3>
  </div>

  <table class="table table-striped" *ngIf="endPoints?.length">
    <thead>
      <tr>
        <th>
          Endpunkt-URL
        </th>
        <th>
          Default-Route
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let endPoint of endPoints"
        [class.success_default_route]="endPoint['defaultRoute'] === true"
      >
        <td>
          {{ endPoint.url }}
        </td>
        <td>
          <span>
            <i class="fa"
              [class.fa-check]="endPoint.defaultRoute"
              [class.fa-minus]="!endPoint.defaultRoute"
              aria-hidden="true"></i>
          </span>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="panel-body" *ngIf="!loading && entities.length === 0">
    <div class="alert alert-info">
      Es wurden keine Einträge gefunden.
    </div>
  </div>
</div>
