import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CrudHelperService } from '@kdose/ng-crud';
import { NotificationService } from '../shared/notification.service';
import { BaseCrudService } from '../shared/base-crud.service';

@Injectable()
export class ClientService extends BaseCrudService {

  constructor(protected helperService: CrudHelperService,
    protected notifyService: NotificationService,
    protected router: Router,
    protected http: HttpClient
  ) {
    super('clients', helperService, notifyService, router, http);
  }
}
