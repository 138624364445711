import { Component, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import PrimeNGTranslation from '../environments/primeNgTranslation.json';
import { HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '@kdose/ng-auth';
import { NotificationService } from './shared/notification.service';
import { SKIP_HTTP_ERRORS } from './shared/http-error-interceptor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private auth: AuthenticationService,
    private pConfig: PrimeNGConfig,
    private notify: NotificationService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.pConfig.setTranslation( PrimeNGTranslation );
    const headers = new HttpHeaders({
      [SKIP_HTTP_ERRORS]: [401]
    });
    this.auth.initializeAuthentication({ headers }).catch((error) => {
      this.notify.acknowledgeRequired('Fehler beim Initialisieren', 'Server nicht erreichbar oder antwortet nicht', 'error');
      console.log({error});
    });
  }

  dismissMessage( key: string) {
    this.messageService.clear(key);
  }
}
