<div class="modal-backdrop fade in" [style.display]="showModal ? 'block' : 'none'"></div>
<div class="modal"
     [class.huge]="huge"
     tabindex="-1"
     role="dialog"
     style="display: block"
     [style.display]="showModal ? 'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          {{ title }}
        </h4>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <button type="button"
                class="btn btn-default btn-sm"
                (click)="cancelAction()">
          {{ cancelLabel }}
        </button>
        <button type="button"
                *ngIf="confirm"
                class="btn btn-primary btn-sm"
                (click)="positiveAction()">
          {{ positiveLabel }}
        </button>
      </div>
    </div>
  </div>
</div>