import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CrudHelperService } from '@kdose/ng-crud';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { baseCrudUrlGetter } from '../../config-getter';
import { NotificationService } from '../../shared/notification.service';
import { BaseCrudService } from '../../shared/base-crud.service';
import { Certificate, CertificateDetails } from '.';

@Injectable()
export class CertificateService extends BaseCrudService {

  constructor(helperService: CrudHelperService,
    notifyService: NotificationService,
    router: Router,
    http: HttpClient
  ) {
    super('certificates', helperService, notifyService, router, http);
  }

  /**
   * Speichern (create und update) eine Entität. Hier wird der Custom-Content-Type verwendet.
   * @param  {any} entity Zu speichernde Entität.
   * @return {Rx.Observable} hot Observable
   */
  saveAtSpecialEndpoint(entity: any, endpoint: string): Observable<Certificate> {
    return this.post(entity, endpoint);
  }

  /**
   * Speichern eines noch nicht vorhandenen Zertifikates.
   * @param  {any}                  entity Zu speichernde Entität.
   * @return {Observable<Response>}        hot Observable
   */
  post(entity: any, endpoint: string): Observable<Certificate> {
    return from(baseCrudUrlGetter()).pipe(
      mergeMap(baseUrlAsync => {
        const headers = (new HttpHeaders()).set('X-KDO-NO-CONTENT-TYPE', '');
        return this.http.post<Certificate>(baseUrlAsync + this.endpoint + (endpoint ? '/' + endpoint : ''), entity, { headers })
      })
    );
  }

  /**
   * Holt die Details zu einem Certificate
   * @param id
   */
  getDetails(id: number): Observable<CertificateDetails> {
    return this.get(id, 'details');
  }

  getX509(id: number): Observable<string> {
    return from(baseCrudUrlGetter()).pipe(
      mergeMap(baseUrlAsync => this.http.get(`${baseUrlAsync}${this.endpoint}/${id}/x509`, {responseType: 'text'})));
  }
}
